
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="tag_create_dialog create-tag-dialog">
  <div class="content create-rename-tag" *ngIf="!conversation && !message && !isMultiple">
    <div *ngIf="conversation || message" class="message">
      {{ "TAGS" | translate }}
    </div>
    <div id="create-tag-header" *ngIf="!isRename && !conversation && !message" class="message">
      {{ "CREATE_TAG" | translate }}
    </div>
    <div id="rename-tag-header" *ngIf="isRename && !conversation && !message" class="message">
      {{ 'EDIT_TAG_LBL' | translate }}
    </div>
    <div>
      <mat-form-field>
        <input id="create-tag-input" [(ngModel)]="tagName" #tagNameInput matInput placeholder="{{ 'TAG_NAME_LBL'| translate }}"
          (keyup)="changeText($event)" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
          [maxLength]="maxTagNameLength">
        <mat-hint>{{tagNameInput.value.length}}/{{maxTagNameLength}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="color-action" *ngIf="!message">
      <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)"
        [(ngModel)]="tagColor">
        <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
        <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
        <mat-radio-button class="blue-radio" value="#20ae80"></mat-radio-button>
        <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
        <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
        <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
        <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
      </mat-radio-group>
      <button id="create-tag-more-color" (click)="openColorDialog()" class="brand-color mat-button">
        <mat-icon class="disable-select">more_horiz</mat-icon>
      </button>
    </div>
    <div class="actions">
      <a id="create-tag-cancel" (click)="closeDialog()">{{ 'CANCEL' | translate }}</a>
      <a id="create-tag-createbtn" [class.action-disabled]="!isValidTagName()" *ngIf="!isRename"
        (click)="save()">{{ 'CRAETE_FODLER_LBL' | translate }}</a>
      <a [class.action-disabled]="!isValidTagName()" *ngIf="isRename" (click)="save()">{{ 'SAVE' | translate }}</a>
    </div>
  </div>

  <div *ngIf="conversation || message || isMultiple">
    <div class="tag__dialog-header" layout="row center-center">
      <div class="mobile-back-button" [fxHide.gt-sm]="true">
        <button (click)="close()" class="brand-color mat-button">
          <mat-icon class="disable-select">chevron_left</mat-icon>
        </button>
      </div>
      <h4>{{ 'TAGS' | translate}}</h4>
      <div class="desktop-close-button" [fxHide.xs]="true">
        <button class="brand-color mat-button" (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="content">
      <mat-tab-group [selectedIndex]="selectedIndex" [disableRipple]="true">
        <!-- Add New Tags tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-tag-plus"></mat-icon>
            <span>{{ 'ADD_NEW_TAG' | translate}}</span>
          </ng-template>
          <div>
            <div class="tab-add-tag-part">
              <span class="detail-msg">{{ 'ADD_NEW_TAG' | translate}}</span>
              <div>
                <mat-form-field class="tag-name-input">
                  <input [(ngModel)]="tagName" #tagNameInput matInput placeholder="{{ 'TAG_NAME_LBL'| translate }}"
                    (keyup)="changeText($event)" autocomplete="off" autocorrect="off" autocapitalize="off"
                    spellcheck="false" [maxLength]="maxTagNameLength">
                </mat-form-field>
                <a (click)="addTag()" [class.action-disabled]="!isValidTagName()" class="add-link">{{ 'COMMON.ADD' | translate }}</a>
              </div>
              <div class="color-action">
                <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)"
                  [(ngModel)]="tagColor">
                  <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
                  <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
                  <mat-radio-button class="blue-radio" value="#20ae80"></mat-radio-button>
                  <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
                  <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
                  <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
                  <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
                </mat-radio-group>
                <button (click)="openColorDialog()" class="brand-color mat-button">
                  <mat-icon class="disable-select">more_horiz</mat-icon>
                </button>
              </div>
            </div>
            <div class="tab-assigned-tag-part">
              <span class="assigned-tag">{{ 'ASSIGNED_TAG' | translate}}</span>
              <div class="mail-tags">
                <div class="mail-tag" *ngFor="let tag of assignedTags"
                  [style.backgroundColor]="tag.rgb || tag.color || tagColor" [style.color]="getTagColor(tag.rgb || tag.color || tagColor)">
                  <span class="no-select">{{ tag.name }}</span>
                  <mat-icon (click)="removeTag(tag)">close</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <!-- Select from existing tags -->
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>local_offer</mat-icon>
            <span>{{ 'SELECT_FROM_EXISTING_TAG' | translate}}</span>
          </ng-template>
          <div>
            <div class="tab-add-tag-part">
              <div class="header-item-tag-dialog">
                <span class="detail-msg">{{ 'ADD_FROM_EXISTING_TAG' | translate}}</span>
                <span class="search-tag">
                  <input matInput [(ngModel)]="searchTag" type="text" placeholder="{{'SEARCH_TAGS' | translate}}" />
                </span>
              </div>
              <div class="mail-tags">
                <div class="mail-tag" *ngFor="let tag of tags | vpTagNameSearch : searchTag;" (click)="addTagToAssign(tag)"
                  [style.backgroundColor]="tag.rgb || tag.color || tagColor" [style.color]="getTagColor(tag.rgb || tag.color || tagColor)">
                  <span class="no-select">{{ tag.name }}</span>
                </div>
              </div>
            </div>
            <div class="tab-assigned-tag-part">
              <span class="assigned-tag">{{ 'ASSIGNED_TAG' | translate}}</span>
              <div class="mail-tags">
                <div class="mail-tag" *ngFor="let tag of assignedTags"
                  [style.backgroundColor]="tag.rgb || tag.color || tagColor" [style.color]="getTagColor(tag.rgb || tag.color || tagColor)">
                  <span class="no-select">{{ tag.name }}</span>
                  <mat-icon (click)="removeTag(tag)">close</mat-icon>
                </div>
              </div>
              <div style="margin-top: 16px;">
                <mat-checkbox [(ngModel)]="showGlobalTags" (change)="toggleShowGlobalTags(showGlobalTags)">{{ 'SHOW_GLOBAL_TAGS' | translate }}</mat-checkbox>
              </div>

            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="tag_dialog-footer">
      <span>{{ 'ADD_TAGS_MENU_ITEM' | translate }}</span>
      <button mat-button (click)="saveTags()">
        <mat-icon class="disable-select">check</mat-icon>
      </button>
    </div>
  </div>
</div>
