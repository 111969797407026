
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="mobile_Sidebar_createfolder_popup" class="mail_folder-create_dialog">
  <div class="content">
    <ng-container >
      <vnc-header
      class="message"
      title="{{ 'CALENDARS.NEW_CALENDER_LBL' | translate }}"
      type="title-close"
      size="l"
      [showCloseIcon]="true"
      (onCloseClick)="closeDialog()"
      >
      </vnc-header>
    </ng-container>
    <div>
      <!-- <vnc-input #folderNameInput [showLabel]="true"  ></vnc-input> -->
      <vnc-input #folderNameInput placeholder="{{ 'CALENDARS.CALENDAR_NAME_LBL' | translate }}" [showLabel]="true" [form]="folderTitleControl" ></vnc-input>

    </div>
    <div class="actions">
      <div class="color-action">
        <mat-radio-group class = "create-folder-color-selection" (change)="updateDefaultColor($event)"
        [(ngModel)]="folderColor" >
          <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
          <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
          <mat-radio-button class="blue-radio" value="#20ae80"></mat-radio-button>
          <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
          <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
          <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
          <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
        </mat-radio-group>
        <button id="mobile_Sidebar_createfolder_morecplor" (click)="openColorDialog()" class="brand-color mat-button">
          <mat-icon class="disable-select">more_horiz</mat-icon>
        </button>
      </div>
      <div class="action-btn">
        <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL_MAIL_LABEL' | translate }}" (click)="closeDialog()"></vnc-button>
        <vnc-button *ngIf="!isRename" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{folderTitleControl.value.length < 1 ? 'disabled' : 'primary'}}" (click)="folderAction()">
            <span>{{'CRAETE_FODLER_LBL' | translate }}</span>
        </vnc-button>
        <vnc-button *ngIf="isRename" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{folderTitleControl.value.length < 1 ? 'disabled' : 'primary'}}" (click)="folderAction()">
            <span>{{'RENAME_FODLER_LBL' | translate }}</span>
        </vnc-button>
      </div>
    </div>
  </div>
</div>