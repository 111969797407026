
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, OnInit, OnDestroy, Component, Inject, ChangeDetectorRef, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ToastService } from "../../../../common/providers/toast.service";
import { MailService } from "../../services/mail-service";
import { MailUtils } from "../../../utils/mail-utils";
import { take } from "rxjs/operators";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { Store } from "@ngrx/store";
import { SearchState } from "../../../../reducers/search";
import { AppState } from "../../../../reducers/app";
import { MailRootState } from "../../../store";
import { PreferenceState } from "../../../../preference/store/reducers";
import { AuthService } from "../../../../common/providers/auth.service";
import { Logout } from "../../../../actions/app";
import { ResetMailFolderState, ResetConversationState } from "../../../store/actions";
import { ResetPreferenceState } from "../../../../preference/store/actions";
import { environment } from "../../../../../environments/default.environment.electron";
import { ConfigService } from "../../../../config.service";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { CommonUtils } from "../../../../common/utils/common-util";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";

@Component({
    selector: "vp-mobile-change-password",
    templateUrl: "./mobile-change-password.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileChangePasswordComponent implements OnInit, OnDestroy {
    changePasswordType = "zimbra";
    urlSafe: SafeResourceUrl;
    type: string;
    oldPassword: boolean = false;
    newPassword: boolean = false;
    confirmPassword: boolean = false;
    oldPasswordInput: string = "";
    newPasswordInput: string = "";
    confirmPasswordInput: string = "";
    showRedirect: boolean = false;
    constructor(
        private dialogRef: MatDialogRef<MobileChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        public sanitizer: DomSanitizer,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private mailService: MailService,
        private store: Store<SearchState | AppState | MailRootState | PreferenceState>,
        private auth: AuthService,
        private config: ConfigService,
        private mailBroadCaster: MailBroadcaster,
        private ngZone: NgZone
    ) {
        this.mailBroadCaster.on<any>(BroadcastKeys.HIDE_CHANGE_PASSWORD_DIALOG).pipe(take(1)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.urlSafe = data.changePasswordUrl;
        if (data.changePasswordUrl !== "") {
            this.urlSafe = "";
        }
        this.type = data.type;
        this.showRedirect = false;
        this.changeDetectionRef.markForCheck();
    }

    close() {
        this.dialogRef.close();
    }

    ngOnInit() {
        if (localStorage.getItem("changePasswordType") !== null) {
            this.changePasswordType = localStorage.getItem("changePasswordType");
        }
    }

    ngOnDestroy() { }

    toggleOldPassword() {
        if (this.oldPassword) {
            this.oldPassword = false;
        } else {
            this.oldPassword = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    toggleNewPassword() {
        if (this.newPassword) {
            this.newPassword = false;
        } else {
            this.newPassword = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    toggleConfirmPassword() {
        if (this.confirmPassword) {
            this.confirmPassword = false;
        } else {
            this.confirmPassword = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    changePassword(): void {
        const userEmail: string = MailUtils.getEmailFromStorage();
        const body = {
            oldPassword: this.oldPasswordInput,
            password: this.newPasswordInput,
            email: userEmail
        };
        this.mailService.changePassword(body).pipe(take(1)).subscribe(res => {
            this.showRedirect = true;
            this.changeDetectionRef.markForCheck();
            setTimeout(() => {
                this.logout();
            }, 1000);
        }, err => {
            this.showRedirect = false;
            this.toastService.show(MailConstants.CURRENT_PASSWORD_WRONG);
            this.changeDetectionRef.markForCheck();
        });
    }

    logout(): void {
        this.auth.removeLogin();
        this.store.dispatch(new Logout());
        this.store.dispatch(new ResetMailFolderState());
        this.store.dispatch(new ResetConversationState());
        this.store.dispatch(new ResetPreferenceState());
        this.updateMailListScrollBroadcast(false);
        if (environment.isCordova || environment.isElectron) {
            this.auth.cordovaLogout();
            let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
            initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref;
            window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
            this.config.loginIframe();
            this.changeDetectionRef.markForCheck();
        } else {
            window.location.href = "/api/call-logout";
        }
    }

    updateMailListScrollBroadcast(isHide) {
        if (CommonUtils.isOnIOS) {
            this.mailBroadCaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, isHide);
        }
    }
}
