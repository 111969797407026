
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="accept_share_folder_dialog">
        <div class="mail__dialog-header mobile-header" layout="row center-center">
                {{'ACCEPT_SHARE_LBL' | translate}}
            </div>
    <div class="mail__dialog-body">
        <div [innerHTML]="row1">
        </div>
        <div [innerHTML]="row2" class="row2">
        </div>
        <div>
                {{ 'DO_YOU_WANT_TO_ACCEPT' | translate }}
        </div>
        <div class="input_folder">
            <mat-form-field>
                <input [(ngModel)]="folderTitle" #folderNameInput matInput
                    placeholder="{{ 'FOLDER_NAME_LBL'| translate }}" autocomplete="off" autocorrect="off"
                    autocapitalize="off" spellcheck="false" [maxLength]="maxFolderTitleLength">
                <mat-hint>{{folderNameInput.value.length}}/{{maxFolderTitleLength}}</mat-hint>
            </mat-form-field>
        </div>
        <div class="color-action">
            <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)"
                [(ngModel)]="folderColor">
                <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
                <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
                <mat-radio-button class="blue-radio" value="#0071bb"></mat-radio-button>
                <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
                <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
                <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
                <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
            </mat-radio-group>
            <button (click)="openColorDialog()" class="brand-color mat-button">
                <mat-icon class="disable-select">more_horiz</mat-icon>
            </button>
        </div>
        <div class="drop_down">
            <mat-form-field>
                <mat-select [(ngModel)]="messageType">
                    <mat-option value="1">{{ 'MESSAGE_OPTION2' | translate }}</mat-option>
                    <mat-option value="2">{{ 'MESSAGE_OPTION1' | translate }}</mat-option>
                    <mat-option value="3">{{ 'MESSAGE_OPTION3' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <div *ngIf="messageType!=='2'" class="note">
                <b>{{ 'NOTE' | translate}}</b> : {{ 'RECEIVER_SHARE_NOTE' | translate }}
            </div>
            <div *ngIf="messageType==='3'" class="textarea_div">
                <mat-form-field>
                    <textarea matInput [(ngModel)]="note" cdkTextareaAutosize [cdkAutosizeMaxRows]=4 [cdkAutosizeMinRows]=2 placeholder="{{ 'MESSAGE_COLON'| translate }}"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="actions">
            <a [class.action-disabled]="folderTitle.length < 1" (click)="acceptFolder()">{{ 'COMMON.YES' | translate }}</a>
            <a (click)="close()">{{ 'COMMON.NO' | translate }}</a>
        </div>
</div>