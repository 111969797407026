
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, ElementRef, OnInit, Inject, AfterViewInit } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { ViewChild } from "@angular/core";
import { VNCCreateContactComponent } from "vnc-library";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AvatarCropperDialogComponent } from "../avatar-cropper-dialog/avatar-cropper-dialog.component";
import { ContactService } from "src/app/contacts/services/contact-service";
import { take, takeUntil } from "rxjs";
import { ConfigService } from "src/app/config.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { RootState, getVNCDContactList } from "src/app/reducers";
import { TranslateService } from "@ngx-translate/core";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { DatabaseService } from "src/app/services/db/database.service";
import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { SetUserContacts } from "src/app/actions/app";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { isArray } from "util";

@Component({
  selector: "vp-create-contact-dialog",
  templateUrl: "./create-contact-dialog.component.html",
  styles: []
})
export class CreateContactDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  disabled = false;
  private isAlive$ = new Subject<boolean>();
  @ViewChild(VNCCreateContactComponent) createContactComponent: VNCCreateContactComponent;
  favoriteFolderId: any;
  lang: string = "en";
  tags: any[];
  contact: any;
  contactList: any[];
  translation;
  isMobileScreen: boolean;
  constructor(
    private mailService: MailService,
    private el: ElementRef,
    private commonRepository: CommonRepository,
    private toastService: ToastService,
    private config: ConfigService,
    private translate: TranslateService,
    private contactService: ContactService,
    private matDialog: MatDialog,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private hotKeyService: NgxHotkeysService,
    private formBuilder: FormBuilder,
    private databaseService: DatabaseService,
    private breakpointObserver: BreakpointObserver,
    private matDialogRef: MatDialogRef<VNCCreateContactComponent>) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.contactService.getFavoriteList().subscribe(v => {
      if (v && v.folder && v.folder[0] && v.folder[0].folder
        && v.folder[0].folder[0]?.absFolderPath === "/favorite") {
          this.favoriteFolderId = v.folder[0].folder[0].id;
      }
    });
    this.contact = data;
    this.commonRepository.getTagsList().pipe(takeUntil(this.isAlive$))
      .subscribe(tags => {
          this.tags = tags.map(tag => {
              return tag.name;
          });
      });
    this.config.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res !== "en") {
        this.lang = "de";
      }
    });
    this.store.select(getVNCDContactList).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.contactList = res.map(v => {
        return {value: v.id, viewValue: v.name};
      });
    });
  }

  ngOnInit(): void {
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).pipe(takeUntil(this.isAlive$))
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
      this.isMobileScreen = true;
      } else {
        this.isMobileScreen = false;
      }
  });
    if (!!this.el.nativeElement.closest("mat-dialog-container")) {
      this.el.nativeElement.closest("mat-dialog-container").classList.add("vnc-create-contact-dialog-container");
      if (this.isMobileScreen) {
        this.el.nativeElement.closest("mat-dialog-container").classList.add("mobile");
      }
    }
    if (!!this.el.nativeElement.closest(".cdk-overlay-pane")) {
      this.el.nativeElement.closest(".cdk-overlay-pane").classList.add("custom-vnc-overlay-pane");
    }
  }

  ngAfterViewInit() {
    if (this.contact) {
      this.translation = this.createContactComponent.defaultTranslation;
      this.translate.get(Object.keys(this.createContactComponent.defaultTranslation)).pipe(take(1)).subscribe(v => {
        this.translation = v;
        setTimeout(() => {
          this.createContactComponent.translation = v;
        }, 500);
        this.createContactComponent.setOptions();
      });
      if (this.contact.item) {
        setTimeout(() => {
          if (!this.contact.isEdit) {
            this.createContactComponent.getForm().patchValue({
              first_name: this.contact.item.p,
              emails_attributes: [
                {
                  email: this.contact.item.a || ""
                }
              ]
            });
          } else {
            this.createContactComponent.getForm().patchValue({
              first_name: this.contact.item.first_name || "",
              middle_name: this.contact.item.middle_name || "",
              last_name: this.contact.item.last_name || "",
              job_title: this.contact.item.job_title || "",
              company: this.contact.item.company || "",
              notes: this.contact.item.notes || "",
              contact_list_ids: [this.contact.item.contact_lists.length ? this.contact.item.contact_lists.map(c => c.id)[0] : []],
            });

            if (this.contact.item.emails && this.contact.item.emails.length) {
              const emailsFG = this.contact.item.emails.map(email => {
                let emailGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  email: email.email
                } : {
                  email: email.email,
                  id: email.id
                };
                return this.formBuilder.group(emailGroupObject)
              });
              const emailsFormArray: FormArray = this.formBuilder.array(emailsFG);
              this.createContactComponent.getForm().setControl('emails_attributes', emailsFormArray);
              // emailsFG.forEach(fg => (<FormArray>this.createContactComponent.getForm().get('emails_attributes')).push(fg));
            }

            if (this.contact.item.addresses && this.contact.item.addresses.length) {
              const addressesFG = this.contact.item.addresses.map(address => {

                let addressGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  address_type: (address.address_type.indexOf("[") > -1 && address.address_type.indexOf("]") > -1) ? [JSON.parse(address.address_type)] : [[address.address_type]],
                  city: address.city === null ? "" : address.city,
                  street1: address.street1 === null ? "" : address.street1,
                  state: address.state === null ? "" : address.state,
                  postcode: address.postcode === null ? "" : address.postcode,
                  country_code: address.country_code === null ? "" : address.country_code
                } : {
                  id: address.id,
                  address_type: (address.address_type.indexOf("[") > -1 && address.address_type.indexOf("]") > -1) ? [JSON.parse(address.address_type)] : [[address.address_type]],
                  city: address.city === null ? "" : address.city,
                  street1: address.street1 === null ? "" : address.street1,
                  state: address.state === null ? "" : address.state,
                  postcode: address.postcode === null ? "" : address.postcode,
                  country_code: address.country_code === null ? "" : address.country_code
                };

                return this.formBuilder.group(addressGroupObject)
              });
              const addressesFormArray: FormArray = this.formBuilder.array(addressesFG);
              this.createContactComponent.getForm().setControl('addresses_attributes', addressesFormArray);

              // addressesFG.forEach(fg => (<FormArray>this.createContactComponent.getForm().get('addresses_attributes')).push(fg));
            }

            if (this.contact.item.phones && this.contact.item.phones.length) {
              const phoneFG = this.contact.item.phones.map(phone => {

                let phoneGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  phone: phone.phone,
                  phone_type: (phone.phone_type.indexOf("[") > -1 && phone.phone_type.indexOf("]") > -1) ? [JSON.parse(phone.phone_type)] : [[phone.phone_type]]
                } : {
                  id: phone.id,
                  phone: phone.phone,
                  phone_type: (phone.phone_type.indexOf("[") > -1 && phone.phone_type.indexOf("]") > -1) ? [JSON.parse(phone.phone_type)] : [[phone.phone_type]]
                };

                return this.formBuilder.group(phoneGroupObject)
              });
              const phoneFormArray: FormArray = this.formBuilder.array(phoneFG);
              this.createContactComponent.getForm().setControl('phones_attributes', phoneFormArray);
            }

            if (this.contact.item.urls && this.contact.item.urls.length) {
              const urlFG = this.contact.item.urls.map(url => {

                let urlGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  url: url.url,
                  url_type: (url.url_type.indexOf("[") > -1 && url.url_type.indexOf("]") > -1) ? [JSON.parse(url.url_type)] : [[url.url_type]]
                } : {
                  id: url.id,
                  url: url.url,
                  url_type: (url.url_type.indexOf("[") > -1 && url.url_type.indexOf("]") > -1) ? [JSON.parse(url.url_type)] : [[url.url_type]]
                };

                return this.formBuilder.group(urlGroupObject)
              });
              const urlFormArray: FormArray = this.formBuilder.array(urlFG);
              this.createContactComponent.getForm().setControl('urls_attributes', urlFormArray);
            }

            if (this.contact.item.im_accounts && this.contact.item.im_accounts.length) {
              const imAccountFG = this.contact.item.im_accounts.map(imAccount => {

                let imAccountGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  im_id: imAccount.im_id,
                  im_type: (imAccount.im_type.indexOf("[") > -1 && imAccount.im_type.indexOf("]") > -1) ? [JSON.parse(imAccount.im_type)] : [[imAccount.im_type]]
                } : {
                  id: imAccount.id,
                  im_id: imAccount.im_id,
                  im_type: (imAccount.im_type.indexOf("[") > -1 && imAccount.im_type.indexOf("]") > -1) ? [JSON.parse(imAccount.im_type)] : [[imAccount.im_type]]
                };

                return this.formBuilder.group(imAccountGroupObject)
              });
              const imAccountFormArray: FormArray = this.formBuilder.array(imAccountFG);
              this.createContactComponent.getForm().setControl('im_accounts_attributes', imAccountFormArray);
            }

            if (this.contact.item.events && this.contact.item.events.length) {
              const eventsFG = this.contact.item.events.map(event => {

                let eventGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  event_type: (event.event_type.indexOf("[") > -1 && event.event_type.indexOf("]") > -1) ? [JSON.parse(event.event_type)] : [[event.event_type]],
                  event_date: event.event_date
                } : {
                  id: event.id,
                  event_type: (event.event_type.indexOf("[") > -1 && event.event_type.indexOf("]") > -1) ? [JSON.parse(event.event_type)] : [[event.event_type]],
                  event_date: event.event_date
                };

                return this.formBuilder.group(eventGroupObject)
              });
              const eventsFormArray: FormArray = this.formBuilder.array(eventsFG);
              this.createContactComponent.getForm().setControl('events_attributes', eventsFormArray);
            }

            if (this.contact.item.custom_fields && this.contact.item.custom_fields.length) {
              const customFieldsFG = this.contact.item.custom_fields.map(cf => {

                let cfGroupObject = (this.contact.item.is_global && this.contact.item.is_global.toString() === "true") ? {
                  field_name: cf.field_name,
                  field_value: cf.field_value
                } : {
                  id: cf.id,
                  field_name: cf.field_name,
                  field_value: cf.field_value
                };

                return this.formBuilder.group(cfGroupObject)
              });
              const customFieldsFormArray: FormArray = this.formBuilder.array(customFieldsFG);
              this.createContactComponent.getForm().setControl('custom_fields_attributes', customFieldsFormArray);
            }

            this.createContactComponent.tags = this.contact.item.tags.map(t => t.name);
            this.createContactComponent.setAvatar(this.contact.item?.avatar);
          }
        });
      } else if (this.contact.vcard) {
        const vcard = this.contact.vcard;
        let contact: any = {};
        if (vcard.email && Array.isArray(vcard.email)) {
          contact.emails_attributes = [];
          vcard.email.forEach((v, i) => {
            if (i > 0) {
              this.createContactComponent.addEmail();
            }
            contact.emails_attributes.push({email: v.value === "null" ? "" : v.value});
          });
        }
        if (vcard.tel && Array.isArray(vcard.tel)) {
          contact.phones_attributes = [];
          vcard.tel.forEach((v, i) => {
            if (i > 0) {
              this.createContactComponent.addPhone();
            }
            contact.phones_attributes.push({
              phone: v.value === "null" ? "" : v.value,
              phone_type: v?.meta?.work?.length ? "work" : ( v?.meta?.mobile?.length ? "mobile" : "home")
            });
          });
        }
        if (vcard.url && Array.isArray(vcard.url)) {
          contact.urls_attributes = [];
          vcard.url.forEach((v, i) => {
            if (i > 0) {
              this.createContactComponent.addURL();
            }
            contact.urls_attributes.push({
              url: v.value  === "null" ? "" : v.value,
              url_type: "home"
            });
          });
        }
        if (vcard.n && vcard.n[0] && vcard.n[0].value && Array.isArray(vcard.n[0].value)) {
          contact.first_name = vcard.n[0].value[1] === "null" ? "" : vcard.n[0].value[1];
          contact.middle_name = vcard.n[0].value[2] === "null" ? "" : vcard.n[0].value[2];
          contact.last_name = vcard.n[0].value[0] === "null" ? "" : vcard.n[0].value[0];
        }
        if (vcard.org && vcard.org[0] && vcard.org[0].value) {
          contact.company = vcard.org[0].value === "null" ? "" : vcard.org[0].value;
        }
        if (vcard.title && vcard.title[0] && vcard.title[0].value) {
          contact.job_title = vcard.title[0].value === "null" ? "" : vcard.title[0].value;
        }
        if (vcard.note && vcard.note[0] && vcard.note[0].value) {
          contact.notes = vcard.note[0].value;
        }
        if (vcard.adr && Array.isArray(vcard.adr)) {
          contact.addresses_attributes = [];
          vcard.adr.forEach((v, i) => {
            if (i > 0) {
              this.createContactComponent.addAddress();
            }
            contact.addresses_attributes.push({
              address_type: "work",
              city: (v.value[1] === "null" ? "" : v.value[1]) || "",
              street1: (v.value[0]  === "null" ? "" : v.value[0]) || "",
              state: (v.value[2]  === "null" ? "" : v.value[2]) || "",
              postcode: (v.value[3]  === "null" ? "" : v.value[3]) || [""],
              country_code: (v.value[4]  === "null" ? "" : v.value[4]) || ""
            });
          });
        }
        this.createContactComponent.getForm().patchValue(contact);
      }
    }
  }

  close() {
    this.matDialogRef.close();
  }

  apply() {
    console.log("apply", this.createContactComponent.getFormData());
    if (this.createContactComponent.getForm().valid) {
      const contact = this.createContactComponent.getFormData();
      contact.contact_list_ids = contact.contact_list_ids || [];
      if (this.createContactComponent.tags) {
        contact.tag_list = this.createContactComponent.tags.join(",");
      }
      if  (this.isURL(contact.avatar_base64)) {
        delete contact.avatar_base64;
      }
      if (this.contact?.item?.is_global && this.contact?.item?.is_global === "true") {
        contact.create_duplicate = "1";
        this.contactService.createVNCdContact(contact).subscribe(v => {
          if (v && v.contact) {
            this.databaseService.addUsers([v.contact]);
          }
          if (v && v.contact && this.createContactComponent.isFav && this.favoriteFolderId) {
              const contact_list_ids = v.contact.contact_lists || [];
              contact.contact_list_ids = [...contact_list_ids, this.favoriteFolderId];
              this.contactService.addContactToGroup({
                contact_ids: v.contact.id,
                group_id: this.favoriteFolderId
              }).subscribe(res => {
                console.log("addContactToGroup", res);
              });
          }
          this.toastService.show("CONTACT_CREATED_MSG");
          this.matDialogRef.close();
        });
      } else if ((!this.contact?.item?.is_global || this.contact?.item?.is_global === "false") && this.contact?.item?.id) {
        contact.id = this.contact.item.id;
        this.contactService.updateVNCdContact(contact).subscribe(v => {
          if (v && v.contact) {
            this.databaseService.addUsers([v.contact]);
          }
          this.toastService.show("CONTACT_UPDATED_MSG");
          this.matDialogRef.close();
        });
      }
      else {
        contact.create_duplicate = "1";
        this.contactService.createVNCdContact(contact).subscribe(v => {
          if (v && v.contact) {
            this.databaseService.addUsers([v.contact]);
            this.getUserContacts();
          }
          if (v && v.contact && this.createContactComponent.isFav && this.favoriteFolderId) {
              const contact_list_ids = v.contact.contact_lists || [];
              contact.contact_list_ids = [...contact_list_ids, this.favoriteFolderId];
              this.contactService.addContactToGroup({
                contact_ids: v.contact.id,
                group_id: this.favoriteFolderId
              }).subscribe(res => {
              });
          }
          this.toastService.show("CONTACT_CREATED_MSG");
          this.matDialogRef.close();
        });
      }
    }
  }

  uploadAvatar() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      panelClass: "mail__dialog",
      data: {cropOnly: true}
    };
    this.matDialog.open(AvatarCropperDialogComponent, dialogArgs).afterClosed().subscribe(data => {
      if (!!data && data.image) {
        this.createContactComponent.setAvatar(data.image);
      }
    });
  }

  addCustomField() {
    console.log("addCustomField");
    this.createContactComponent.addCustomField();
    setTimeout(() => {
      if (document.querySelector(".custom-fields")) {
        document.querySelector(".custom-fields").scrollIntoView({behavior: "smooth", block: "nearest"});
      }
    }, 500);
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  cancel() {
    this.matDialogRef.close();
  }

  isURL(urlString) {
    try {
      return urlString.indexOf("https") > -1 || urlString.indexOf("http") > -1;
    }
    catch(e){
      return false;
    }
  }

  getUserContacts(): void {
    const groupMemberIds: any [] = [];
    this.mailService.getAllUserContacts().pipe(take(1)).subscribe(res => {
      if (!!res && res.cn && isArray(res.cn)) {
        const contacts = res.cn;
        contacts.map( c => {
          if (!!c.m && c.m !== null) {
            groupMemberIds.push(c.id);
          }
        });
        this.store.dispatch(new SetUserContacts(contacts));
        this.databaseService.createOrUpdateContacts(contacts).subscribe(() => {
        });
      }
    });
  }

}
