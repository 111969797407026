
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit,
    ElementRef, ViewChild, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Subject } from "rxjs";
import { MailFolder } from "../../../models/mail-folder.model";
import { MailFolderRepository } from "../../../repositories/mail-folder-repository";
import { ToastService } from "../../../../common/providers/toast.service";
import { MailRootState } from "../../../store";
import { Store } from "@ngrx/store";
import { getMailFolders } from "../../../store/selectors";
import { takeUntil, filter, take, skip } from "rxjs/operators";
import { ColorControlDialogComponent } from "../../../color-control-dialog/color-control-dialog.component";
import { Grant } from "../../../models/acl.model";
import { getUserProfile, getZimbraFeatures, getCalendarFolders } from "../../../../reducers";
import { isArray } from "util";
import { MailService } from "../../services/mail-service";
import { ShareFolderRevokeComponent } from "../share-folder-revoke-dialog/share-folder-revoke-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { ShareFolderComponent } from "../share-folder/share-folder.component";
import { MailUtils } from "../../../utils/mail-utils";
import { getBriefcaseFolders, BriefcaseRootState } from "src/app/briefcase/store/selectors";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CalendarFolder } from "src/app/common/models/calendar.model";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";

@Component({
    selector: "vp-edit-folder-properties",
    templateUrl: "./folder-edit-properties-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFolderPropertiesComponent implements OnDestroy, AfterViewInit {

    folderTitle: string = "";
    maxFolderTitleLength: number = 128;
    private isAlive$ = new Subject<boolean>();
    mailRootFolders: MailFolder[] = null;
    folderColor: string;
    grant: Grant[] = [];
    fromAddress: string;
    grantorId: string;
    showRetention: boolean = false;
    custom: string = "custom";
    rangeUnit: string = "y";
    thresholdUnit: string = "y";
    enableDisposal: boolean = false;
    enableRention: boolean = false;
    thresholdValue: string = "";
    retensionValue: string = "";
    isSharingFeatureEnabled: boolean = true;
    briefcaseShareURL: string = "";
    isShowAddShareButton: boolean = true;
    isShowRetention: boolean = true;
    retentionChange: boolean = false;
    @ViewChild("folderNameInput", {static: false}) folderNameInput: ElementRef<HTMLInputElement>;
    UNITS = [
        {
            days: 1,
            unit: "d"
        },
        {
            days: 7,
            unit: "w"
        },
        {
            days: 31,
            unit: "m"
        },
        {
            days: 366,
            unit: "y"
        }
    ];

    moduleType = "mail";
    rootBriefcaseFolder = null;
    constructor(
        private dialogRef: MatDialogRef<EditFolderPropertiesComponent>,
        private mailFolderRepository: MailFolderRepository,
        public toastService: ToastService,
        private changeDetectionRef: ChangeDetectorRef,
        private mailBroadcaster: MailBroadcaster,
        private store: Store<MailRootState | BriefcaseRootState | CalendarState>,
        private ngZone: NgZone,
        private matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private hotKeyService: NgxHotkeysService,
        private mailService: MailService,
        private translate: TranslateService,
        private calendarRepository: CalendarRepository
    ) {
        setTimeout(() => {
            this.changeDetectionRef.detectChanges();
          }, 50);
        this.hotKeyService.pause(this.hotKeyService.hotkeys);
        this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            if (res.email) {
                this.checkEmailArray(res.email);
            } else {
                this.checkEmailArray(MailUtils.getEmailFromStorage());
            }
        });
        this.folderTitle = this.data.targetFolder.name;
        console.log(this.data);

        this.moduleType = this.data.moduleType;
        let dataSelector = null;
        if (this.moduleType === "mail") {
            dataSelector = getMailFolders;
        } else if (this.moduleType === "calendar") {
            dataSelector = getCalendarFolders;
        } else {
            dataSelector = getBriefcaseFolders;
        }
        if (this.data.targetFolder.rgb) {
            this.folderColor = this.data.targetFolder.rgb.toLowerCase();
            this.changeDetectionRef.markForCheck();
        }
        if (!this.data.targetFolder) {
            this.store.select(dataSelector).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
                if (res) {
                    this.mailRootFolders = res;
                }
            });
        }
        this.changeDetectionRef.markForCheck();

        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_EDIT_FOLDER_PROPERTIES_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });

        this.mailBroadcaster.on<any>("UPDATE_BRIEFCASE_FOLDERS").subscribe(val => {
            this.store.select(dataSelector).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
                if (res) {
                    this.getFolder(res);
                }
            });
        });

        setTimeout(() => {
            const selector = <HTMLElement> document.querySelector(".mail_edit_folder_properties_dialog");
            if (!!selector && selector !== null) {
                selector.click();
            }
            if (!!this.folderNameInput && this.folderNameInput !== null) {
                this.folderNameInput.nativeElement.focus();
            }
        }, 200);

        if (this.data.targetFolder && this.data.targetFolder.acl) {
            this.grant = this.data.targetFolder.acl.grant;
            this.getGrantorID();
            this.changeDetectionRef.markForCheck();
        }

        if (this.moduleType === "calendar") {
            this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
                if (res) {
                    this.getCalendarFolder(res);
                }
            });
        }
        this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.isSharingFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_SHARING_ENABLED);
        });
        if (this.data.briefcaseShareURL) {
            this.briefcaseShareURL = this.data.briefcaseShareURL;
        }
        if (this.data.targetFolder && this.data.targetFolder.perm && this.data.targetFolder.perm === "r") {
            this.isShowAddShareButton = false;
            this.isShowRetention = false;
            this.changeDetectionRef.markForCheck();
        }
        if (this.moduleType === "mail" && this.isShowRetention) {
            this.showRetention = true;
            this.changeDetectionRef.markForCheck();
            this.setRetensionValue();
        }

        if (this.data.targetFolder.id === "16" ) {
            this.translate.get("BRIEFCASE_TITLE").pipe(take(1)).subscribe(
                text => {
                  this.folderTitle = text;
                }
              );
        } else if (this.data.targetFolder && this.data.targetFolder.id === "2") {
            this.translate.get("INBOX").pipe(take(1)).subscribe(text => {
              this.folderTitle = text;
            });
        } else if (this.data.targetFolder && this.data.targetFolder.id === "6") {
            this.translate.get("DRAFTS_FOLDER").pipe(take(1)).subscribe(text => {
              this.folderTitle = text;
            });
        } else if (this.data.targetFolder && this.data.targetFolder.id === "10") {
            this.translate.get("CALENDARS.CALENDAR_FOLDER").pipe(take(1)).subscribe(text => {
              this.folderTitle = text;
            });
        }
    }

    changeText(event) {
        if (this.folderTitle.length > this.maxFolderTitleLength) {
            this.folderTitle = this.folderTitle.substring(0, this.maxFolderTitleLength);
            this.changeDetectionRef.markForCheck();
        }
    }

    closeDialog(): void {
        this.close();
    }

    updateDefaultColor(event) {
        this.folderColor = event.value;
        this.changeDetectionRef.markForCheck();
    }

    folderAction(): void {
        if (this.folderTitle.length < 1) {
            return;
        }
        if (this.moduleType === "mail") {
            if (this.enableDisposal && (this.thresholdValue === null || +this.thresholdValue <= 0)) {
                this.toastService.show("RETENTION_POSITIVE_NUMBER_ERROR");
                return;
            }
            if (this.enableRention && (this.retensionValue === null || +this.retensionValue <= 0)) {
                this.toastService.show("RETENTION_POSITIVE_NUMBER_ERROR");
                return;
            }

            this.updateRetension();
        }
        if (this.data.targetFolder.id === "2" || this.data.targetFolder.id === "6" || this.data.targetFolder.id === "5") {
            this.close();
            return;
        }
        if (this.isFolderAlreadyExist()) {
            this.toastService.show("DUPLICATE_FOLDER_MSG");
            return;
        }
        if (this.moduleType === "mail") {
            let changeFolderName = true;
            if (this.data.targetFolder.name === this.folderTitle) {
                changeFolderName = false;
            }
            if (changeFolderName) {
                this.mailFolderRepository.updateMailFolder(this.data.targetFolder, this.folderTitle, this.folderColor);
            } else {
                this.mailFolderRepository.updateMailFolder(this.data.targetFolder, this.folderTitle, this.folderColor, true);
            }
        } else if (this.moduleType === "calendar") {
            this.calendarRepository.updateCalendarFolder(this.data.targetFolder , this.folderTitle, this.folderColor);
        } else {
            if (this.data.targetFolder.id === "16" ) {
                this.folderTitle = "Briefcase";
            }
            this.mailBroadcaster.broadcast("updateBriefcaseFolder", {
                targetFolder: this.data.targetFolder, folderTitle: this.folderTitle, folderColor: this.folderColor});
        }
        this.close();
    }

    openColorDialog() {
        const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
            height: "auto",
            maxHeight: "70%",
            width: "99%",
            maxWidth: "244px",
            autoFocus: false,
            panelClass: "vp-color-control-dialog",
            data: { folderColor: this.folderColor }
        });
        changeColorDialogRef.afterClosed().subscribe(operation => {
            if (!!operation && operation !== null && operation.selectedColor) {
                this.folderColor = operation.selectedColor;
            }
        });
    }

    isFolderAlreadyExist() {
        if (!this.data.targetFolder && this.mailRootFolders.find(folder => folder.name === this.folderTitle)) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    }

    close(): void {
        this.dialogRef.close();
    }

    edit(grant: Grant): void {
        const dialog = this.matDialog.open(ShareFolderComponent, {
            autoFocus: false,
            panelClass: "mail_folder-share_dialog",
            data: {
                targetFolder: this.data.targetFolder,
                isRename: false,
                folderName: this.data.targetFolder.name,
                grant: grant,
                briefcaseShareURL: this.briefcaseShareURL
            }
        });
        dialog.afterClosed().subscribe(res => {
            this.hotKeyService.pause(this.hotKeyService.hotkeys);
            this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
        });
    }

    checkEmailArray(emails): void {
        if (isArray(emails)) {
            this.fromAddress = emails[0];
        } else if (emails) {
            this.fromAddress = emails;
        }
    }

    revoke(grant: Grant, index?: number): void {
        const dialog = this.matDialog.open(ShareFolderRevokeComponent, {
            maxWidth: "none",
            autoFocus: false,
            panelClass: "mail_share_folder_revoke",
            data: { targetFolder: this.data.targetFolder, grant: grant, grantorId: this.grantorId }
        });
        dialog.afterClosed().subscribe(res => {
            const index = this.grant.findIndex((v) => v.zid === grant.zid);
            if (!!res && res.revoke && !!res.revoke) {
                if (index !== -1) {
                    this.grant.splice(index, 1);
                    this.changeDetectionRef.markForCheck();
                }
                this.hotKeyService.pause(this.hotKeyService.hotkeys);
                this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
            }
        });
    }

    resend(grant: Grant): void {
        const createEmail = this.createEmail(grant);
        this.sendEmail(
            createEmail.subject,
            createEmail.htmlContent,
            createEmail.plainContent,
            createEmail.xmlShare,
            grant
        );
    }

    createEmail(grant: Grant): any {
        const grantee = grant.gt === "guest" ? grant.zid : grant.d;
        const folderName = this.data.targetFolder.name;
        let rights = "";
        let role = "";
        if (grant.perm === "r") {
            rights = "View";
            role = "Viewer";
        } else if (grant.perm === "rwidx") {
            rights = "View, Edit, Add, Remove";
            role = "Manager";
        } else if (grant.perm === "rwidxa") {
            rights = "View, Edit, Add, Remove, Administer";
            role = "Admin";
        }

        let plainText = "";
        this.translate.get("RESEND_PLAIN_TEXT", {
            fromAddress: this.fromAddress,
            folderName: folderName,
            grantee: grantee,
            role: role,
            rights: rights
        }).pipe(take(1)).subscribe((text: string) => {
            plainText = text;
        });

        let htmlContent = "";

        this.translate.get("RESEND_HTML_TEXT", {
            fromAddress: this.fromAddress,
            folderName: folderName,
            grantee: grantee,
            role: role,
            rights: rights
        }).pipe(take(1)).subscribe((text: string) => {
            htmlContent = text;
        });

        const xmlShare = "<share xmlns='urn:zimbraShare' version='0.2' action='new' >" +
            "<grantee id=\"" + grant.zid + "\" email=\"" + grantee + "\" name=\"" + grantee + "\" />" +
            "<grantor id=\"" + this.grantorId + "\" email=\"" + this.fromAddress + "\" name=\"" + this.fromAddress + "\" />" +
            "<link id=\"" + this.data.targetFolder.id + "\" name=\"" + folderName + "\" view=\"message\" perm=\"" + grant.perm + "\" />" +
            "<notes></notes>" +
            "</share>";

        let subject = "";
        this.translate.get("EDIT_PROPERTIES_MAIL_SUBJECT", {
            fromAddress: this.fromAddress,
            folderName: folderName
        }).pipe(take(1)).subscribe((text: string) => {
            subject = text;
        });

        return { plainText: plainText, htmlContent: htmlContent, xmlShare: xmlShare, subject: subject };
    }

    getGrantorID(): void {
        this.mailService.getAttributes("idents").pipe(take(1)).subscribe(res => {
            this.grantorId = res.id;
        });
    }

    sendEmail(subject: string, htmlContent: string, plainContent: string, shareContent: string, grant: Grant) {
        const grantee = grant.gt === "guest" ? grant.zid : grant.d;
        const request = {
            su: subject,
            content: htmlContent,
            e: [
                {
                    a: grantee,
                    t: "t"
                },
                {
                    a: this.fromAddress,
                    t: "f"
                }
            ],
            mp: [
                {
                    "@": {
                        ct: "multipart/alternative"
                    },
                    mp: [
                        {
                            "@": {
                                ct: "text/plain"
                            },
                            content: plainContent
                        },
                        {
                            "@": {
                                ct: "text/html"
                            },
                            content: htmlContent
                        },
                        {
                            "@": {
                                ct: "xml/x-zimbra-share"
                            },
                            content: shareContent
                        }
                    ]
                }
            ]
        };

        const sendRequest = {
            SendMsgRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                m: request
            }
        };

        this.mailService.createBatchRequest(sendRequest).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.toastService.show("SHARE_NOTICE_SENT");
        });
    }

    shareDialog(): void {
        const dialog = this.matDialog.open(ShareFolderComponent, {
            autoFocus: false,
            panelClass: "mail_folder-share_dialog",
            data: {
                targetFolder: this.data.targetFolder,
                isRename: false,
                folderName: this.data.targetFolder.name ,
                briefcaseShareURL: this.briefcaseShareURL
            }
        });
        dialog.afterClosed().subscribe(res => {
            if (!!res) {
                const perm = res.perm;
                const grantItem: any = {};
                if (res.folderResponse && Array.isArray(res.folderResponse)) {
                    res.folderResponse.map(item => {
                        grantItem.d = item.action.d;
                        grantItem.zid = item.action.zid;
                        grantItem.gt = "usr";
                        grantItem.perm = perm;
                        if (this.data.targetFolder.acl && this.data.targetFolder.acl.grant) {
                            this.data.targetFolder.acl.grant.push(grantItem);
                        } else {
                            this.data.targetFolder.acl = {
                                grant: [grantItem]
                            };
                        }
                    });
                    this.grant = this.data.targetFolder.acl.grant;
                    this.changeDetectionRef.markForCheck();
                }
            }
            this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
            this.hotKeyService.pause(this.hotKeyService.hotkeys);
        });
    }

    getFolder(mailFolder: MailFolder[]): void {
        const folders: MailFolder[] = [];
        if (!!mailFolder && mailFolder !== null && mailFolder.length > 0) {
            mailFolder.forEach(item => {
                folders.push(item);
                if (item.children) {
                    folders.push(...MailUtils.getChildFolders([item]));
                }
            });
        }
        if (!!this.data && !!this.data.targetFolder) {
            const findFolder = folders.filter(v => !!v).filter(item => item.id === this.data.targetFolder.id)[0];
            this.data.targetFolder = findFolder;
            if (this.data.targetFolder && this.data.targetFolder.acl) {
                this.setGrant(this.data.targetFolder);
            }
        }
    }

    setGrant(targetFolder) {
        this.grant = targetFolder.acl.grant;
        this.getGrantorID();
        this.changeDetectionRef.markForCheck();
    }

    changeRetension(ev): void {
        if (ev.checked) {
            this.showRetention = true;
        } else {
            this.showRetention = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    changeRetensionBox(ev): void {
        if (ev.checked) {
            this.enableRention = true;
        } else {
            this.enableRention = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    changeDisposalBox(ev): void {
        if (ev.checked) {
            this.enableDisposal = true;
        } else {
            this.enableDisposal = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    private setRetensionValue(): void {
        if (this.data.targetFolder.retentionPolicy) {
            const retensionPolicy = this.data.targetFolder.retentionPolicy[0];
            if (retensionPolicy.keep && retensionPolicy.keep[0].policy) {
                const lifeTime = retensionPolicy.keep[0].policy[0].lifetime;
                const valuesAndUnit = this.getUnitsAndValue(lifeTime);
                this.rangeUnit = valuesAndUnit.units;
                this.retensionValue = valuesAndUnit.values;
                this.enableRention = true;
            }
            if (retensionPolicy.purge && retensionPolicy.purge[0].policy) {
                const lifeTime = retensionPolicy.purge[0].policy[0].lifetime;
                const valuesAndUnit = this.getUnitsAndValue(lifeTime);
                this.thresholdUnit = valuesAndUnit.units;
                this.thresholdValue = valuesAndUnit.values;
                this.enableDisposal = true;
            }
            this.changeDetectionRef.markForCheck();
        }
    }

    private getUnitsAndValue(lifetime: string): any {
        let conversionFactor = 1;
        const interval = lifetime.slice(lifetime.length - 1);
        const unitAndValues = {
            units: "",
            values: ""
        };
        switch (interval) {
            case "d": conversionFactor = 1; break;
            case "h": conversionFactor = 24; break;
            case "m": conversionFactor = 1440; break;
            case "s": conversionFactor = 86400; break;
            case "ms": conversionFactor = 86400000; break;
            default: conversionFactor = 86400; break;
        }
        const lifeTimeValue = parseInt(lifetime, 10);
        const days = Math.floor((lifeTimeValue - 1) / conversionFactor) + 1;
        for (let i = this.UNITS.length - 1; i >= 0; i--) {
            if ((days >= this.UNITS[i].days) && ((days % this.UNITS[i].days) === 0)) {
                unitAndValues.units = this.UNITS[i].unit;
                unitAndValues.values = (days / this.UNITS[i].days).toString();
                break;
            }
        }
        return unitAndValues;
    }

    private setValue(value, unit): string {
        let returnValue = "";
        switch (unit) {
            case "y":
                returnValue = (parseInt(value, 10) * 366).toString() + "d";
                break;
            case "m":
                returnValue = (parseInt(value, 10) * 31).toString() + "d";
                break;
            case "d":
                returnValue = (parseInt(value, 10) * 1).toString() + "d";
                break;
            case "w":
                returnValue = (parseInt(value, 10) * 7).toString() + "d";
                break;
        }
        return returnValue;
    }

    private updateRetension(): void {
        const retentionPolicy = {
            keep: {},
            purge: {}
        };
        if (this.enableRention) {
            retentionPolicy.keep = {
                policy: {
                    type: "user",
                    lifetime: this.setValue(this.retensionValue, this.rangeUnit).toString()
                }
            };
        }
        if (this.enableDisposal) {
            retentionPolicy.purge = {
                policy: {
                    type: "user",
                    lifetime: this.setValue(this.thresholdValue, this.thresholdUnit).toString()
                }
            };
        }
        const body = {
            id: this.data.targetFolder.id,
            policy: retentionPolicy,
            op: "retentionpolicy"
        };
        this.mailFolderRepository.updateRetentionFolder(this.data.targetFolder, body);
    }

    getCalendarFolder(mailFolder: CalendarFolder[]): void {
        const folders: CalendarFolder[] = [];
        if (!!mailFolder && mailFolder !== null && mailFolder.length > 0) {
            mailFolder.forEach(item => {
                folders.push(item);
                if (item.folder) {
                    folders.push(...CalenderUtils.getChildFolders([item]));
                }
            });
        }
        const findFolder = folders.filter(v => !!v).filter(item => item.id === this.data.targetFolder.id)[0];
        this.data.targetFolder = findFolder;
        if (this.data.targetFolder && this.data.targetFolder.acl) {
            this.setGrant(this.data.targetFolder);
        }
    }

    ngAfterViewInit() {
        console.log("[EditFolderPropertiesComponent]");
    }
}
