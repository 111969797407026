
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        <div class="header-text">{{ 'CALENDARS.ADD_EXTERNAL_CALENDER' | translate }}</div>
    </div>
    <div class="content">
        <div>
            <mat-form-field>
                <mat-label>{{ 'CALENDARS.TYPE_LBL' | translate }}</mat-label>
                <mat-select disableRipple [(ngModel)]="externalType">
                    <mat-option value="caldev">{{ 'CALENDARS.CAL_DEV_ACCOUNT' | translate }}</mat-option>
                    <mat-option value="ical">{{ 'CALENDARS.ICAL_SUBSCRIPTION' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="externalType === 'caldev'">
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.EMAIL_ADDRESS_LBL' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'CALENDARS.EMAIL_ADDRESS_EXAMPLE_LBL' | translate }}"
                        [(ngModel)]="externalEmail">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.PASSWORD_LBL' | translate }}</mat-label>
                    <input matInput type="password" [(ngModel)]="externalPassword">
                </mat-form-field>

            </div>
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.CAL_DEV_SERVER' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'CALENDARS.WWW_EXAMPLE_COM_LBL' | translate }}"
                        [(ngModel)]="externalServer">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="externalType === 'ical'">
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'CALENDARS.ICS_URL_LBL' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'CALENDARS.WWW_EXAMPLE_COM_LBL' | translate }}"
                        [(ngModel)]="externalIcalUrl">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="footer">
        <div>
        </div>
        <div>
            <button mat-button (click)="nextPage()">
                {{ 'CALENDARS.NEXT_LBL' | translate }}
            </button>
            <button mat-button (click)="close()">
                {{ 'COMMON.CANCEL' | translate }}
            </button>
        </div>
    </div>
</div>