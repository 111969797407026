
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="print-dialog-view">
    <div class="header">
        <div class="header-text">{{ 'CALENDARS.PRINT_CALENDAR_LBL' | translate }}</div>
        <div class="header-icon" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="mobile-header">
        <div class="header-icon" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
        <div class="header-text">{{ 'CALENDARS.PRINT_CALENDAR_LBL' | translate }}</div>
    </div>
    <div class="left-right">
        <div class="left">
            <div class="calendar-lbl">
                {{ 'CALENDARS.CALENDARS' | translate }}
            </div>
            <cdk-virtual-scroll-viewport itemSize="10" class="folders-viewport">
                <ng-container *cdkVirtualFor="let item of calendarFolders"></ng-container>
                <mat-tree [dataSource]="calendarDataSource" [treeControl]="calendarTreeControl"
                    class="sidebar-tree-subfolders">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                        style="white-space:no-wrap;" class="sub_folder_tree_node">
                        <li *ngIf="node" class="sub_folder">
                            <div class="mat-tree-node">
                                <button mat-icon-button disabled></button>
                                <div class="folder-information">
                                    <div class="folder-div disable-select">
                                        <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                                            (change)="itemSelectionToggle(node, $event)" class="disable-select">
                                        </mat-checkbox>
                                        <span *ngIf="!node.perm">
                                            <mat-icon *ngIf="node.id!=='3'" class="mdi-16px disable-select"
                                                fontSet="mdi" fontIcon="mdi-calendar-blank"
                                                [style.color]="node.folderColor">
                                            </mat-icon>
                                            <mat-icon *ngIf="node.id==='3'" class="mdi-16px disable-select"
                                                fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                                        </span>
                                        <span *ngIf="node.perm">
                                            <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                                fontIcon="mdi-account-multiple" [style.color]="node.folderColor">
                                            </mat-icon>
                                        </span>
                                        <span class="sidebar-title disable-select"
                                            title="{{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}"
                                            [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                            {{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node; when: hasNestedChild" matTreeNodePadding
                        style="white-space:no-wrap;" class="sub_folder_tree_node">
                        <li *ngIf="node" class="sub_folder">
                            <div class="mat-tree-node">
                                <button *ngIf="calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                                </button>

                                <button *ngIf="!calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
                                </button>
                                <div class="folder-information">
                                    <!-- <div (click)="routeToFolder(node)" class="folder-div"> -->
                                    <div class="folder-div disable-select">
                                        <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                                            class="disable-select" (change)="itemSelectionToggle(node, $event)">
                                        </mat-checkbox>
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            fontIcon="mdi-calendar-blank" [style.color]="node.folderColor"
                                            *ngIf="!node.perm && node.id !== '3'"></mat-icon>
                                        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-delete"
                                            *ngIf="!node.perm && node.id === '3'"></mat-icon>
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            [style.color]="node.folderColor" fontIcon="mdi-account-multiple"
                                            *ngIf="node.perm"></mat-icon>
                                        <span class="sidebar-title disable-select"
                                            title="{{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}"
                                            [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                            {{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                </mat-tree>
            </cdk-virtual-scroll-viewport>
        </div>
        <div class="right">
            <div class="radio-select-division">
                <div class="radio-buttons">
                    <mat-radio-group [(ngModel)]="selectedRadio">
                        <div class="lbl-radio">{{ 'CALENDARS.SELCTED_DATE_LBL' | translate }}:</div>
                        <mat-radio-button value="currentDate">
                            <div>
                                <button class="cal-button-arrow-drop-down appt-btn" (click)="todayDate.open()"
                                    [disabled]="selectedRadio === 'dateRange'">
                                    <input class="cal-mat-date-picker-input" [matDatepicker]="todayDate"
                                        [formControl]="todayDateFormControl" />
                                    <span
                                        class="cal-mat-date-picker-span">{{ todayDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                    <mat-datepicker #todayDate [touchUi]=true></mat-datepicker>
                                </button>
                            </div>
                            <div>
                                <button mat-button [disabled]="selectedRadio === 'dateRange'"
                                    (click)="setTodayDateItem()" class="today-print-btn">
                                    {{ 'CALENDARS.TODAY' | translate }}
                                </button>
                            </div>
                        </mat-radio-button>
                        <div class="lbl-radio">{{ 'CALENDARS.DATE_RANGE_LBL' | translate }}:</div>
                        <mat-radio-button value="dateRange">
                            <div class="date-range-div">
                                <div>
                                    <button class="cal-button-arrow-drop-down appt-btn" (click)="startDate.open()"
                                        [disabled]="selectedRadio === 'currentDate'">
                                        <input class="cal-mat-date-picker-input" [matDatepicker]="startDate"
                                            [formControl]="startDateFormControl" />
                                        <span
                                            class="cal-mat-date-picker-span">{{ startDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                        <mat-datepicker #startDate [touchUi]=true></mat-datepicker>
                                    </button>
                                </div>
                                <div>{{ 'CALENDARS.TO_TEXT_LBL' | translate }}</div>
                                <div>
                                    <button class="cal-button-arrow-drop-down appt-btn" (click)="endDate.open()"
                                        [disabled]="selectedRadio === 'currentDate'">
                                        <input class="cal-mat-date-picker-input" [matDatepicker]="endDate"
                                            [formControl]="endDateFormControl" />
                                        <span
                                            class="cal-mat-date-picker-span">{{ endDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                        <mat-datepicker #endDate [touchUi]=true></mat-datepicker>
                                    </button>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="bottom-part">
                <div class="lable-item">
                    {{ 'CALENDARS.PRINT_VIEW_LBL' | translate }}:
                </div>
                <div class="print-view">
                    <button [matMenuTriggerFor]="printTypes" class="appt-btn">
                        <span *ngIf="printViewType === 'day'">{{ 'CALENDARS.DAY_VIEW_TITLE' | translate }}</span>
                        <span *ngIf="printViewType === 'week'">{{ 'CALENDARS.WEEK_VIEW_TITLE' | translate }}</span>
                        <span *ngIf="printViewType === 'month'">{{ 'CALENDARS.MONTH_VIEW_TITLE' | translate }}</span>
                        <span *ngIf="printViewType === 'list'">{{ 'CALENDARS.LIST_VIEW_TITLE' | translate }}</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                </div>
                <div class="lable-item">
                    {{ 'CALENDARS.HOURS_LBL' | translate }}:
                </div>
                <div class="hours-div" *ngIf="printViewType !=='month' && printViewType !=='list'">
                    <div>
                        <button class="cal-button-arrow-drop-down appt-btn" [matMenuTriggerFor]="startTimePointMenu">
                            <span>{{ startTimeControl | date: "hh:mm a" }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </div>
                    <div>
                        {{ 'CALENDARS.TO_TEXT_LBL' | translate }}
                    </div>
                    <div>
                        <button class="cal-button-arrow-drop-down appt-btn" [matMenuTriggerFor]="endTimePointMenu">
                            <span>{{ endTimeControl | date: "hh:mm a" }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="lable-item">
                    {{ 'PREFERENCES.OPTIONS' | translate }}:
                </div>
                <div class="options">
                    <mat-checkbox *ngIf="printViewType === 'day'" [(ngModel)]="oneDayPage">
                        {{ 'CALENDARS.PRINT_ONE_DAY_PAGE_LBL' | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="printViewType === 'week' || printViewType === 'month'"
                        [(ngModel)]="oneDayPage">
                        {{ 'CALENDARS.PRINT_WORK_DAYS_ONLY' | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="printViewType === 'week'" [(ngModel)]="oneWeekPage">
                        {{ 'CALENDARS.PRINT_ONE_WEEK_PAGE' | translate }}</mat-checkbox>
                    <mat-checkbox [(ngModel)]="minicalendar">
                        {{ 'CALENDARS.INCLUDE_MINI_CALENDER_LBL' | translate }}</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <mat-menu #printTypes="matMenu" class="cal-dropdown-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let option of viewTypeOptions" (click)="changeViewType(option)">
            <span *ngIf="option === 'day'">{{ 'CALENDARS.DAY_VIEW_TITLE' | translate }}</span>
            <span *ngIf="option === 'week'">{{ 'CALENDARS.WEEK_VIEW_TITLE' | translate }}</span>
            <span *ngIf="option === 'month'">{{ 'CALENDARS.MONTH_VIEW_TITLE' | translate }}</span>
            <span *ngIf="option === 'list'">{{ 'CALENDARS.LIST_VIEW_TITLE' | translate }}</span>
        </button>
    </mat-menu>
    <mat-menu #startTimePointMenu="matMenu" class="cal-dropdown-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let timepoint of calendarComposeView.startTimePointOptions"
            (click)="handleStartTimeChanges(timepoint)">
            <span>{{ timepoint | date: "hh:mm a" }}</span>
        </button>
    </mat-menu>

    <mat-menu #endTimePointMenu="matMenu" class="cal-dropdown-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let timepoint of calendarComposeView.endTimePointOptions"
            (click)="handleEndTimeChanges(timepoint)">
            <span>{{ timepoint | date: "hh:mm a" }}</span>
        </button>
    </mat-menu>
    <mat-divider></mat-divider>
    <div class="footer">
        <button mat-button (click)="close()">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-button (click)="printCalendar()">
            {{ 'CALENDARS.PRINT' | translate }}
        </button>
    </div>
    <div class="mobile-footer">
        <span>{{ 'CALENDARS.PRINT_CALENDAR_LBL' | translate }}</span>
        <button mat-button (click)="printCalendar()">
            <mat-icon class="disable-select">print</mat-icon>
        </button>
    </div>
</div>