
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { MailRootState, getSelectedMailFolder } from "../store";
import { CreateMailFolder, CreateMailFolderFail, UpdateMailFolderSuccess, DeleteMailFolder } from "../store/actions";
import { ErrorService } from "../../common/providers/error-service";
import { ErrorType } from "../../common/enums/mail-enum";
import { MailService } from "../shared/services/mail-service";
import { MailFolder } from "../models/mail-folder.model";
import { getMailFolders } from "../store/selectors";
import { ToastService } from "../../common/providers/toast.service";
import { DeleteMailFolderFail, DeleteMailFolderSuccess,
  UpdateMailFolderFail, CreateMailFolderSuccess } from "../store/actions/mail-folder.action";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConversationRepository } from "./conversation.repository";
import { Router } from "@angular/router";
import { SaveSearch, CreateSearch } from "../../actions/search";
import { TranslateService } from "@ngx-translate/core";
import { take, sampleTime, debounceTime } from "rxjs/operators";
import { Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { CommonRepository } from "./common-repository";
import { MailBroadcaster } from "../../common/providers/mail-broadcaster.service";
import * as _ from "lodash";
import { ElectronService } from "src/app/services/electron.service";
import { getViewBy } from "src/app/reducers";
import { IdbUtils } from "src/app/services/idb.service";
import { UpdateCalendarFolderSuccess } from "src/app/actions/calendar.actions";
import { CreateSearchFolder } from "src/app/actions/search-folder.action";
import { SearchFolder } from "src/app/shared/models/search-folder";
import { MailUtils } from "src/app/mail/utils/mail-utils";

@Injectable()
export class MailFolderRepository {

  mailFolders: MailFolder[] = [];
  flatFolders = {};
  flatArrayFolders = [];
  selectedMailFolder: MailFolder = null;
  constructor(private mailService: MailService,
    private store: Store<MailRootState>,
    private errorService: ErrorService,
    private toastService: ToastService,
    private conversationRepository: ConversationRepository,
    private router: Router,
    private translate: TranslateService,
    private mailBroadcaster: MailBroadcaster,
    private commonRepository: CommonRepository) {
      console.log("[MailFolderRepository] constructor");
      console.log("[MailFolderRepository] constructor 2");
      this.mailBroadcaster.on<any>("MAIL_FOLDER_REPOSITORY_SELECTED_MAIL_FOLDER").subscribe(res => {
        this.selectedMailFolder = res;
      });
      this.mailBroadcaster.on<any>("MAIL_FOLDER_UPDATE_AFTER_MOVE").pipe(debounceTime(1000)).subscribe(res => {
        this.commonRepository.getMailFolders();
      });
  }

  createFlatFolder(folders: MailFolder[]): void {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      if (!!folder.zid) {
        if (this.mailService.mailShareZids.indexOf(folder.zid) === -1) {
          this.mailService.mailShareZids.push(folder.zid);
        }
      }
      this.mailService.flatFolders[folder.id] = folder;
      this.mailService.flatArrayFolders.push(folder);
      if (folder.perm) {
        if (folder.id.indexOf(":") !== -1 ) {
          this.mailService.sharedIds.push("inid:" + "\"" + folder.id + "\"");
        } else {
          this.mailService.sharedIds.push("inid:" + folder.id);
        }
      }
      if (folder.children) {
        this.createFlatFolder(folder.children);
      }
    }
  }

  setChildrenFolders(folders: MailFolder[], flatFolders) {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      flatFolders[folder.id] = folder;
      if (folder.children) {
        this.setChildrenFolders(folder.children, flatFolders);
      }
    }
  }

  getChildFolders(folders: MailFolder[]): MailFolder[] {
    let allFolders: MailFolder[] = [];
    let childFolders: MailFolder[] = [];
    folders.filter(f => f.children && f.children.length > 0).forEach(f => {
      childFolders = [...childFolders, ...f.children];
      allFolders = this.getChildFolders(childFolders);
    });
    return [...allFolders, ...childFolders];
  }

  public getMailSearchFolders() {
    this.commonRepository.getSearchFolders("mail");
  }

  public getContactSearchFolders() {
    this.commonRepository.getSearchFolders("contact");
  }

  public getCalendarSearchFolders() {
    this.commonRepository.getSearchFolders("calendar");
  }
  public getBriefSearchFolders() {
    this.commonRepository.getSearchFolders("briefcase");
  }

  createMailFolder(targetFolder: MailFolder, title, color?: string) {
    this.store.dispatch(new CreateMailFolder());
    let targetFolderId = null;
    if (targetFolder) {
      targetFolderId = targetFolder.id;
    } else {
      targetFolderId = "1";
    }
    let body = null;
    if (color) {
      body = { folderId: targetFolderId, name: title, view: "message", rgb: color};
    } else {
      body = { folderId: targetFolderId, name: title, view: "message"};
    }
    this.mailService.createFolder(body).subscribe(res => {
      if (targetFolder) {
        if (!targetFolder.children) {
          targetFolder.children = [];
        }
        if (!targetFolder.children.find(f => f.id === (res as MailFolder).id)) {
          targetFolder.children.push(res as MailFolder);
        }
        const folder: MailFolder = this.getRootFolder(targetFolder);
        this.mailBroadcaster.broadcast(MailConstants.FOLDER_CREATE_BROADCAST);
        this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
        this.mailBroadcaster.broadcast("MARK_FOR_CHECK_BROADCAST");
        this.commonRepository.getMailUpdatedRootFolder(folder, "FOLDER_CREATED_MSG");
      } else {
        this.store.dispatch(new CreateMailFolderSuccess({ folder: res as MailFolder }));
        this.toastService.show("FOLDER_CREATED_MSG");
      }
    },
    err => {
      this.store.dispatch(new CreateMailFolderFail());
      if (err.toString().includes(MailConstants.SAVE_SEARCH_EXIT)) {
        this.translate.get(MailConstants.SEARCH_ALREADY_EXIST_MSG, { searchName: body.name}).pipe(take(1)).subscribe((text: string) => {
          this.errorService.emit({ id: ErrorType.Generic, messages: text });
        });
      } else if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translate.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.toastService.showPlainMessage(text);
        });
      } else if (err.indexOf("object with that name already exists") !== -1) {
        this.toastService.show("DUPLICATE_FOLDER_MSG");
      } else {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    });
  }

  updateMailFolder(targetFolder: MailFolder, title, folderColor?: string, changeFolderName?: boolean) {
    const oldtitle: string = targetFolder.name;
    this.store.dispatch(new CreateMailFolder());
    this.mailService.folderAction({ id: targetFolder.id, name: title, op: "rename"}).subscribe(res => {
      if (folderColor) {
        this.changeFolderColor(targetFolder, folderColor);
      }
      targetFolder.name = title;
      let folder: MailFolder;
      const updatedAbsPath = targetFolder.absFolderPath.replace(oldtitle, title);
      targetFolder.absFolderPath = updatedAbsPath;
      if (targetFolder.l !==  MailConstants.ROOT_MAIL_FOLDER_ID) {
        folder = this.getRootFolder(targetFolder);
      } else {
        folder = targetFolder;
      }
      let msg = "FOLDER_RENAMED_MSG";
      if (changeFolderName) {
        msg = "FOLDER_WAS_CHANGE_LBL";
      }
      // this.commonRepository.getMailUpdatedRootFolder(folder, msg);
      this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
      this.mailBroadcaster.broadcast("MARK_FOR_CHECK_BROADCAST");
    },
    err => {
      this.store.dispatch(new CreateMailFolderFail());
      if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translate.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.toastService.showPlainMessage(text);
        });
      } else if (err.indexOf("object with that name already exists") !== -1) {
        this.toastService.show("DUPLICATE_FOLDER_MSG");
      } else {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    });
  }

  deleteMailFolder(targetFolder: MailFolder): Observable<any> {
    this.store.dispatch(new DeleteMailFolder());
    const rootFolder: MailFolder = this.getRootFolder(targetFolder);
    this.mailBroadcaster.broadcast(MailConstants.UPDATE_TARGET_FOLDER, this.getParentFolder(targetFolder, rootFolder));
    const response = new Subject<string>();
    this.mailService.folderAction({ id: targetFolder.id, op: "trash" }).subscribe(res => {
      if (targetFolder.l === "1") {
        this.store.dispatch(new DeleteMailFolderSuccess({folder: targetFolder}));
      } else {
        this.removeChildFromParent(targetFolder);
      }
      response.next(MailConstants.TRASH_MAIL_FOLDER_ID);
      if (!!this.selectedMailFolder && this.selectedMailFolder.name === targetFolder.name) {
        this.router.navigate(["/mail/inbox"]);
      }
    },
    err => {
      this.store.dispatch(new DeleteMailFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
    return response.asObservable();
  }

  removeChildFromParent(folder) {
    const updatedFolder = this.mailService.flatFolders[folder.id];
    if (updatedFolder) {
      const parentFolder = MailUtils.getParentById(this.mailService.flatFolders, updatedFolder.l);
      console.log("[removeChildFromParent] found the folder", updatedFolder);
      if (parentFolder) {
        MailUtils.removeChildFolder(parentFolder, folder);
        this.store.dispatch(new UpdateMailFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[removeChildFromParent] found the parent and update", parentFolder);
      }
    }
  }

  permenentDeleteMailFolder(targetFolder) {
    this.store.dispatch(new DeleteMailFolder());
    this.mailService.folderAction({ id: targetFolder.id, op: "delete" }).subscribe(res => {
      const rootFolder: MailFolder = this.getRootFolder(targetFolder);
      if (targetFolder.l !== MailConstants.TRASH_MAIL_FOLDER_ID) {
        const parentFolder: MailFolder = this.commonRepository.getSelectedSubFolder(targetFolder.l, rootFolder);
        parentFolder.children.splice(parentFolder.children.indexOf(targetFolder), 1);
        if (parentFolder.children.length === 0) {
           parentFolder.children = null;
        }
      } else {
        if (rootFolder.children) {
          rootFolder.children.splice(rootFolder.children.indexOf(targetFolder), 1);
        } else if (rootFolder.link) {
          rootFolder.link.splice(rootFolder.link.indexOf(targetFolder), 1);
        }
        if (rootFolder.children && rootFolder.children.length === 0) {
          rootFolder.children = null;
        }
      }
      this.commonRepository.getMailUpdatedRootFolder(rootFolder, "FOLDER_DELETED_MSG");
      if (!!this.selectedMailFolder && this.selectedMailFolder.name === targetFolder.name) {
        this.router.navigate(["/mail/inbox"]);
      }
    },
    err => {
      this.store.dispatch(new DeleteMailFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  emptyMailFolder(targetFolder) {
    this.store.dispatch(new DeleteMailFolder());
    let folderId = "";
    if ( targetFolder.owner && targetFolder.perm ) {
      folderId = targetFolder.zid + ":" + targetFolder.rid;
    } else {
      folderId = targetFolder.id;
    }
    let currentView = "conversation";
    let currentFolder = this.selectedMailFolder;
    this.store.select(getViewBy).pipe(take(1)).subscribe(value => {
      currentView = value;
    });
    this.store.pipe(select(getSelectedMailFolder), take(1)).subscribe(folder => {
      if (folder) {
        currentFolder = folder;
      }
    });
    this.mailService.folderAction({ id: folderId, op: "empty" }).subscribe(res => {
      console.log("[emptyMailFolder]", currentFolder, targetFolder);
      if (currentFolder.name === targetFolder.name) {
        console.log("[emptyMailFolder] removeAllMailOnEmptyFolder", currentView, currentFolder, targetFolder);
        if (currentView === "conversation") {
          this.conversationRepository.removeAllMailOnEmptyFolder(folderId);
          this.conversationRepository.removeMailByFolderId(folderId);
        } else {
          this.conversationRepository.removeAllMessagesOnEmptyFolder(folderId);
          this.conversationRepository.removeMessageMailByFolderId(folderId);
        }
      }
      const rootFolder: MailFolder = this.getRootFolder(targetFolder);
      if (!!rootFolder && rootFolder !== null && rootFolder.id === "3") {
        if (rootFolder.children) {
          rootFolder.children = null;
        } else if (rootFolder.link) {
          rootFolder.link = null;
        }
        if (rootFolder.children && rootFolder.children.length === 0) {
          rootFolder.children = null;
        }
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: rootFolder.id, changes: rootFolder }));
        this.commonRepository.getMailUpdatedRootFolder(rootFolder);
      }
      this.toastService.show("EMPTY_FODLER_SUCCESS_MSG");
      setTimeout(() => {
        this.mailBroadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
        this.commonRepository.getMailFolders();
      }, 300);
    },
    err => {
      this.store.dispatch(new DeleteMailFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  changeFolderColor(targetFolder: MailFolder, folderColor) {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    this.mailService.folderAction({ id: targetFolder.id, op: "color", rgb: folderColor }).subscribe(res => {
      targetFolder.rgb = folderColor;
      const rootFolder: MailFolder = this.getRootFolder(targetFolder);
      const folder: MailFolder = this.getRootFolder(targetFolder);
      this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
    },
    err => {
      this.store.dispatch(new UpdateMailFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  markAllMailRead(targetFolder: MailFolder) {
    this.mailService.folderAction({ id: targetFolder.id, op: "read" }).subscribe(res => {
      targetFolder.u = null;
      const rootFolder: MailFolder = this.getRootFolder(targetFolder);
      this.store.dispatch(new UpdateMailFolderSuccess({ id: rootFolder.id, changes: rootFolder }));
      if (!!this.selectedMailFolder && this.selectedMailFolder.name === targetFolder.name) {
        this.conversationRepository.markReadAllMail();
      }
    },
    err => {
      this.store.dispatch(new UpdateMailFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }


  moveMailFolder(sourceFolderId: string, destinationFolderId: string,sourceFolder?): Observable<any> {
    const response = new Subject<string>();
    if (sourceFolder) {
      this.mailService.folderAction({ id: sourceFolderId, l: destinationFolderId, op: "update" ,name: sourceFolder?.name}).subscribe(res => {
        response.next(destinationFolderId);
      });
    }
    else {
      this.mailService.folderAction({ id: sourceFolderId, l: destinationFolderId, op: "move"}).subscribe(res => {
        response.next(destinationFolderId);
      });
    }
    return response.asObservable();
  }

  getSavedSearchFolders() {
    this.mailService.getSavedSearchFolder().subscribe(res => {
      this.store.dispatch(new SaveSearch({folders: res}));
    });
  }

  saveSearchFolder(options: any) {
    this.mailService.saveSearchFolder(options).subscribe(res => {
      this.store.dispatch(new CreateSearch({folder: res}));
      this.store.dispatch(new CreateSearchFolder({ folder: res as SearchFolder }));
      this.toastService.show("SEARCH_SAVED_MSG");
    }, err => {
      if (err.toString().includes(MailConstants.SAVE_SEARCH_EXIT)) {
        this.translate.get(MailConstants.SEARCH_ALREADY_EXIST_MSG, { searchName: options.name}).pipe(take(1)).subscribe((text: string) => {
          this.errorService.emit({ id: ErrorType.Generic, messages: text });
        });
      } else {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    });
  }

  setRoutedFolder(folderId: string, rootPath: string) {
    this.commonRepository.setRoutedFolder(folderId, rootPath);
  }


  addFolderInTrash (targetFolder: MailFolder) {
    let mailFolders = [];
    this.store.select(getMailFolders).pipe(take(1)).subscribe(res => {
      mailFolders = res;
   });
    const trashFolder: MailFolder = mailFolders.find(folder => folder.name === "Trash");
    if (!trashFolder.children) {
      trashFolder.children = [];
    }
    targetFolder.l = trashFolder.id;
    targetFolder.absFolderPath = "/Trash/" + targetFolder.name;
    trashFolder.children.push(targetFolder);
    this.store.dispatch(new UpdateMailFolderSuccess({ id: trashFolder.id, changes: trashFolder }));
  }

  getRootFolder(targetFolder: MailFolder): MailFolder {
    return this.commonRepository.getRootFolder(targetFolder);
  }

  getAbsolutePathShareFolder (targetFolder: MailFolder) {
    return this.commonRepository.getAbsolutePathShareFolder(targetFolder);
  }

  refreshCurrentRootMailFolder() {
    this.commonRepository.getMailUpdatedRootFolder(this.selectedMailFolder);
  }

  getRootSharedFolder(folderId, rootFolderName: string): MailFolder {
    return this.commonRepository.getRootSharedFolder(folderId, rootFolderName);
  }

  getParentFolder(targetFolder: MailFolder, rootFolder: MailFolder) {
    return this.commonRepository.getParentFolder(targetFolder, rootFolder);
  }

  updateRetentionFolder(targetFolder: MailFolder , body: any) {
    const oldtitle: string = targetFolder.name;
    this.store.dispatch(new CreateMailFolder());
    this.mailService.updateRetensionPolilcy(body).subscribe(res => {
      let folder: MailFolder;
      const updatedAbsPath = targetFolder.absFolderPath;
      targetFolder.absFolderPath = updatedAbsPath;
      if (targetFolder.l !==  MailConstants.ROOT_MAIL_FOLDER_ID) {
        folder = this.getRootFolder(targetFolder);
      } else {
        folder = targetFolder;
      }
      this.commonRepository.getMailUpdatedRootFolder(folder, false);
      this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
    },
    err => {
      this.store.dispatch(new CreateMailFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }
}
