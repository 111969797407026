
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="mobile_Sidebar_createfolder_popup" class="mail_folder-create_dialog">
  <div class="content">
    <ng-container *ngIf="isContactList">
      <!-- <div id="mobile_Sidebar_createfolder_header"class="message">
        {{ "CREATE_LIST" | translate }}
      </div> -->
      <vnc-header
      class="message"
      title="{{ 'CREATE_LIST' | translate }}"
      type="title-close"
      size="l"
      (onCloseClick)="closeDialog()"
      [showCloseIcon]="true"
      closeIconToolTip="{{ 'CLOSE' | translate }}">
      </vnc-header>
    </ng-container>
    <ng-container *ngIf="!isContactList">
      <!-- <div id="mobile_Sidebar_createfolder_header" *ngIf = "!isRename" class="message">
        {{ "ADD_FOLDER" | translate }}
      </div> -->
      <vnc-header
      *ngIf = "!isRename"
      class="message"
      title="{{ 'ADD_NEW_FOLDER' | translate }}"
      type="title-close"
      size="l"
      (onCloseClick)="closeDialog()"
      [showCloseIcon]="true"
      closeIconToolTip="{{ 'CLOSE' | translate }}">
      </vnc-header>
      <!-- <div id="mobile_Sidebar_createfolder_header" *ngIf = "isRename"  class="message">
        {{ "RENAME_FOLDER" | translate }} "{{oldFlderName}}"
      </div> -->
      <vnc-header
      *ngIf = "isRename"
      class="message"
      title="{{ 'RENAME_FOLDER' | translate }}"
      type="title-close"
      size="l"
      (onCloseClick)="closeDialog()"
      [showCloseIcon]="true"
      closeIconToolTip="{{ 'CLOSE' | translate }}">
      </vnc-header>
    </ng-container>
   
    <div>
      <!-- <mat-form-field>
        <input id="mobile_Sidebar_createfolder_name" [(ngModel)]="folderTitle" #folderNameInput matInput placeholder="{{  (isContactList ? 'CONTACT_LIST_NAME_LBL': 'FOLDER_NAME_LBL') | translate }}" (keyup)="changeText($event)" autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false" [maxLength]="maxFolderTitleLength">
        <mat-hint>{{folderNameInput.value.length}}/{{maxFolderTitleLength}}</mat-hint>
      </mat-form-field> -->
      <vnc-input #folderNameInput placeholder="{{  (isContactList ? 'CONTACT_LIST_NAME_LBL': 'FOLDER_NAME_LBL') | translate }}" [showLabel]="true" [form]="folderTitleControl" ></vnc-input>
    </div>
    <div class="actions">
      <div class="color-action">
        <mat-radio-group class = "create-folder-color-selection" (change) = "updateDefaultColor($event)" [(ngModel)]="folderColor">
          <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
          <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
          <mat-radio-button class="blue-radio" value="#20ae80"></mat-radio-button>
          <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
          <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
          <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
          <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
        </mat-radio-group>
        <button id="mobile_Sidebar_createfolder_morecplor" (click)="openColorDialog()" class="brand-color mat-button">
          <mat-icon class="disable-select">more_horiz</mat-icon>
        </button>
      </div>
      <div class="action-btn">
        <!-- <a id="mobile_Sidebar_createfolder_cancelbtn" (click)="closeDialog()">{{ 'CANCEL_MAIL_LABEL' | translate }}</a>
        <a id="mobile_Sidebar_createfolder_createbrn" *ngIf="!isRename" [class.action-disabled]="folderTitleControl.value.length < 1" (click)="folderAction()">{{ 'CRAETE_FODLER_LBL' | translate }}</a>
        <a id="mobile_Sidebar_createfolder_renamebrn" *ngIf="isRename" [class.action-disabled]="folderTitleControl.value.length < 1" (click)="folderAction()">{{ 'RENAME_FODLER_LBL' | translate }}</a> -->

        <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL_MAIL_LABEL' | translate }}" (click)="closeDialog()"></vnc-button>

        <vnc-button *ngIf="!isRename" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{folderTitleControl.value.length < 1 ? 'disabled' : 'primary'}}" (click)="folderAction()">
            <span>{{'CRAETE_FODLER_LBL' | translate }}</span>
        </vnc-button>
        <vnc-button *ngIf="isRename" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{folderTitleControl.value.length < 1 ? 'disabled' : 'primary'}}" (click)="folderAction()">
            <span>{{'RENAME_FODLER_LBL' | translate }}</span>
        </vnc-button>
      </div>
    </div>
  </div>
</div>
