
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-mobile-calendar-contextmenu-dialog",
    templateUrl: "./mobile-calendar-contextmenu.component.html"
})
export class MobileCalendarContextMenuDialogComponent implements OnInit, OnDestroy {

    event: any;
    isRepeatAppointment: boolean = false;
    isSeriesAppointment: boolean = false;
    isInstanceAppointment: boolean = false;
    isSimpleAppointment: boolean = false;
    isReadOnlyFolder: boolean = false;

    seriesContextMenuActions = {
        "moveAction": false,
        "enableReply": false,
        "enableReplyAll": false,
        "enableForward": true,
        "enableTag": false,
        "enableDelete": false,
        "enableReInvite": false
    };
    appointmentContextMenuActions = {
        "cancelAction": false,
        "enableReply": false,
        "enableReplyAll": false,
        "enableForward": true,
        "enableTag": false,
        "enableDelete": false
    };
    instanceContextMenuActions = {
        "cancelAction": false,
        "enableReply": false,
        "enableReplyAll": false,
        "enableForward": true,
        "enableTag": false,
        "enableDelete": false,
        "enableReInvite": false
    };

    constructor(
        private dialogRef: MatDialogRef<MobileCalendarContextMenuDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private calendarRepository: CalendarRepository
    ) {
        this.event = this.data.event;
        if (this.event.isRepeatAppt) {
            this.isRepeatAppointment = true;
        } else {
            this.isSimpleAppointment = true;
        }
    }

    ngOnInit() {
        this.enableDisableMenuItem();
        const folderId = this.event.folderId;
        this.isReadOnlyFolder = this.calendarRepository.isReadOnlyFolder(folderId);
    }

    ngOnDestroy() {
    }

    cancel(): void {
        this.close();
    }

    close(): void {
        this.dialogRef.close();
    }

    submit(type: string, operation: string): void {
        this.dialogRef.close({type: type, operation: operation});
    }

    instanceSelect(): void {
        this.isInstanceAppointment = true;
        this.isRepeatAppointment = false;
        this.isSimpleAppointment = false;
    }

    seriesSelect(): void {
        this.isSeriesAppointment = true;
        this.isRepeatAppointment = false;
        this.isSimpleAppointment = false;
    }

    enableDisableMenuItem(): void {
        this.appointmentContextMenuActions.cancelAction = false;
        this.seriesContextMenuActions.enableReplyAll = false;
        this.appointmentContextMenuActions.enableReplyAll = false;
        this.instanceContextMenuActions.enableReplyAll = false;

        const event = this.event;
        if (event.folderId !== "3" && event.otherAtt && !event.neverSent && event.isOrganizer) {
            this.appointmentContextMenuActions.cancelAction = true;
        }
        if (event.otherAtt) {
            this.seriesContextMenuActions.enableReplyAll = true;
            this.appointmentContextMenuActions.enableReplyAll = true;
            this.instanceContextMenuActions.enableReplyAll = true;
        }
        if (!event.isOrganizer) {
            this.seriesContextMenuActions.enableReply = true;
            this.appointmentContextMenuActions.enableReply = true;
            this.instanceContextMenuActions.enableReply = true;

            this.seriesContextMenuActions.enableDelete = true;
            this.instanceContextMenuActions.enableDelete = true;
            this.appointmentContextMenuActions.enableDelete = true;
        } else {
            this.seriesContextMenuActions.enableReply = false;
            this.appointmentContextMenuActions.enableReply = false;
            this.instanceContextMenuActions.enableReply = false;

            this.seriesContextMenuActions.enableDelete = false;
            this.instanceContextMenuActions.enableDelete = false;
            this.appointmentContextMenuActions.enableDelete = false;
        }

        if (event && !event['at'] && !event.isRepeatAppt) {
            this.seriesContextMenuActions.enableDelete = true;
            this.instanceContextMenuActions.enableDelete = true;
            this.appointmentContextMenuActions.enableDelete = true;
        }

        this.seriesContextMenuActions.moveAction = false;
        const folderId = event.folderId;
        const isReadOnly = this.calendarRepository.isReadOnlyFolder(folderId);
        this.seriesContextMenuActions.enableForward = true;
        this.instanceContextMenuActions.enableForward = true;
        this.appointmentContextMenuActions.enableForward = true;

        this.seriesContextMenuActions.enableTag = true;
        this.instanceContextMenuActions.enableTag = true;
        this.appointmentContextMenuActions.enableTag = true;

        this.seriesContextMenuActions.enableReInvite = false;
        this.instanceContextMenuActions.enableReInvite = false;
        if (!isReadOnly) {
            this.seriesContextMenuActions.moveAction = true;
        } else {
            this.seriesContextMenuActions.enableForward = false;
            this.instanceContextMenuActions.enableForward = false;
            this.appointmentContextMenuActions.enableForward = false;
            this.seriesContextMenuActions.enableTag = false;
            this.instanceContextMenuActions.enableTag = false;
            this.appointmentContextMenuActions.enableTag = false;
            this.seriesContextMenuActions.enableDelete = false;
            this.instanceContextMenuActions.enableDelete = false;
            this.appointmentContextMenuActions.enableDelete = false;
        }
        if (isReadOnly && event.class === "PRI") {
            this.seriesContextMenuActions.enableTag = false;
            this.instanceContextMenuActions.enableTag = false;
            this.appointmentContextMenuActions.enableTag = false;

            this.seriesContextMenuActions.enableDelete = false;
            this.instanceContextMenuActions.enableDelete = false;
            this.appointmentContextMenuActions.enableDelete = false;

            this.seriesContextMenuActions.enableForward = false;
            this.instanceContextMenuActions.enableForward = false;
            this.appointmentContextMenuActions.enableForward = false;

            this.seriesContextMenuActions.moveAction = false;
        }
        if (!isReadOnly && !!event && event.otherAtt && event.isOrganizer) {
            this.seriesContextMenuActions.enableReInvite = true;
            this.instanceContextMenuActions.enableReInvite = true;
        }
    }
}
