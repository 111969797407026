
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { MailFolderActionTypes } from "../actions/mail-folder.action";
import { MailFolder } from "../../models/mail-folder.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";


export interface MailFolderState extends EntityState<MailFolder> {
  selectedMailFolder: MailFolder;
  isLoading: boolean;
  isLoaded: boolean;
}

export const mailFolderAdapter: EntityAdapter<MailFolder> = createEntityAdapter<MailFolder>({
  selectId: (mailFolder: MailFolder) => mailFolder.id
});

export const initialState: MailFolderState = mailFolderAdapter.getInitialState({
  selectedMailFolder: null,
  isLoading: false,
  isLoaded: false
});

export function mailFolderReducer(state = initialState, action: Action): MailFolderState {
  switch (action.type) {
    case MailFolderActionTypes.SELECT_MAIL_FOLDER: {
      return {
        ...state,
       selectedMailFolder: action.payload
      };
    }

    case MailFolderActionTypes.LOAD_FOLDERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailFolderActionTypes.LOAD_FOLDERS_SUCCESS: {
      return mailFolderAdapter.addAll(action.payload.folders, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailFolderActionTypes.LOAD_FOLDERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailFolderActionTypes.CREATE_MAIL_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailFolderActionTypes.CREATE_MAIL_FOLDER_SUCCESS: {
      return mailFolderAdapter.addOne(action.payload.folder, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailFolderActionTypes.CREATE_MAIL_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailFolderActionTypes.DELETE_MAIL_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailFolderActionTypes.DELETE_MAIL_FOLDER_SUCCESS: {
      return mailFolderAdapter.removeOne(action.payload.folder.id, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailFolderActionTypes.DELETE_MAIL_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailFolderActionTypes.UPDATE_MAIL_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MailFolderActionTypes.UPDATE_MAIL_FOLDER_SUCCESS: {
      return mailFolderAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case MailFolderActionTypes.UPDATE_MAIL_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MailFolderActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.MailFolderState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case MailFolderActionTypes.RESET_MAIL_FOLDER_STATE: {
      return mailFolderAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedMailFolder = (state: MailFolderState) => state.selectedMailFolder;
export const _getIsLoading = (state: MailFolderState) => state.isLoading;
export const _getIsLoaded = (state: MailFolderState) => state.isLoaded;
