
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="mainMenu">
    <mat-list>
        <mat-list-item (click)="operationEmail('copy')">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-content-copy"></mat-icon>
                    {{ 'COPY' | translate }}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="findContactMenuEnable()">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                    {{ 'FIND_EMAILS' | translate }}
                </div>
                <div>
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="operationEmail('newMail')">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-email-outline"></mat-icon>
                    {{ 'NEW_EMAIL' | translate }}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="operationEmail('editNewContact', isEditContact)">
            <div class="justyfy-item">
                <div *ngIf="!isEditContact" class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-account-plus"></mat-icon>
                    {{ 'ADD_TO_CONTACTS' | translate }}
                </div>
                <div *ngIf="isEditContact" class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                    {{ 'EDIT_CONTACT' | translate }}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="filterMenuEnable()">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-filter-variant"></mat-icon>
                    {{ 'ADD_FILTER_HEADING' | translate }}
                </div>
                <div>
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="close()">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    {{ 'COMMON.CANCEL' | translate }}
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
<div *ngIf="findContactMenu">
    <mat-list>
        <mat-list-item (click)="operationEmail('receiveFromSender')">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                    {{ 'RECEIVED_FROM_SENDER' | translate }}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="operationEmail('sentSender')">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                    {{ 'SENT_TO_SENDER' | translate }}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="close()">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    {{ 'COMMON.CANCEL' | translate }}
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
<div *ngIf="filterMenu">
    <mat-list>
        <mat-list-item (click)="operationEmail('newFilter')">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                    {{ 'NEW_FILTER' | translate }}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item *ngFor="let filter of incomingFilters;" (click)="operationEmail('editFilter', false, filter)">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-filter-variant"></mat-icon>
                    {{filter.name}}
                </div>
            </div>
        </mat-list-item>
        <mat-list-item (click)="close()">
            <div class="justyfy-item">
                <div class="disable-select">
                    <mat-icon class="mdi-18px disable-select" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    {{ 'COMMON.CANCEL' | translate }}
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
