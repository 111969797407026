
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-sub-folder-menu-dialog mail-dialog">
    <div class="mail__dialog-body mail__dialog-body--pad disable-select" (scroll)="onUserScroll($event)">
        <ng-container *ngIf="!isMenuClicked">
            <div id="mobile_sidebar_folder_folderlist-1" class="sidebar-list disable-select" *ngIf="selectedMailFolder && selectedMailFolder?.children">
                <mat-list>
                    <mat-list-item (click)="getParentFolder()">
                        <div class="folder-icon disable-select">
                            <mat-icon class="disable-select">subdirectory_arrow_left</mat-icon>
                        </div>
                        <div class="folder-title disable-select">
                            <span class="disable-select">...</span>
                        </div>
                    </mat-list-item>
                </mat-list>
                <div *ngIf="selectedMailFolder && selectedMailFolder.children">
                    <cdk-virtual-scroll-viewport class="sub-folders-list sub-folders-inner-block" itemSize="30" style="height: calc(80vh - 80px);">
                        <mat-list>
                            <ng-container *cdkVirtualFor="let folder of selectedMailFolder.children">
                                <mat-list-item vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openActionList(folder)" (click)="routeToFolder(folder)" vpLongPress [timeout]="500">
                                    <div class="folder-icon disable-select">
                                        <mat-icon [style.color]="folder.rgb" class="disable-select">folder</mat-icon>
                                    </div>
                                    <div class="folder-title disable-select">
                                        <span class="disable-select" [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ mailService?.getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                                    </div>
                                    <div class="folder-expander disable-select" *ngIf="folder.children" (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)">
                                        <mat-icon id="mobile_sidebar_folder_expand" class="disable-select">keyboard_arrow_right
                                        </mat-icon>
                                    </div>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
            <div *ngIf="selectedMailFolder && !selectedMailFolder.children">
                <mat-list>
                    <mat-list-item (click)="getParentFolder()">
                        <div class="folder-icon disable-select">
                            <mat-icon class="disable-select">subdirectory_arrow_left</mat-icon>
                        </div>
                        <div class="folder-title disable-select">
                            <span class="disable-select">...</span>
                        </div>
                    </mat-list-item>
                </mat-list>
                <div class="no-folder-division">
                    {{ 'EMPTY_SUB_FOLDER_CREATE_MSG' | translate }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isMenuClicked">
            <div id="mobile_sidebar_folder_rootfolderlist" class="sidebar-list disable-select user-folders" *ngIf="systemFolders">
                <cdk-virtual-scroll-viewport class="system-folders-list" itemSize="30" style="height: 40vh;">
                    <mat-list>
                        <ng-container *cdkVirtualFor="let folder of systemFolders">
                            <mat-list-item (click)="routeToSystemFolder(folder)"  vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openActionList(folder)">
                                <div class="folder-icon disable-select">
                                    <mat-icon *ngIf="folder.icon" [style.color]="folder.rgb ? folder.rgb : '#808080'"
                                        class="disable-select">{{folder.icon}}</mat-icon>
                                    <mat-icon *ngIf="!folder.icon" [style.color]="folder.rgb" class="disable-select">folder</mat-icon>
                                    <span class="mail-count"
                                        *ngIf="folder.name !== 'Drafts' && folder.u !== '0' && folder.u !== 0 && folder.u !== undefined">{{ folder.u > 99 ? '99+' : folder.u }}</span>
                                </div>
                                <div class="folder-title disable-select">
                                    <span class="disable-select"
                                        [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ mailService?.getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                                </div>
                                <div class="folder-expander disable-select" *ngIf="folder.children"
                                    (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)">
                                    <mat-icon id="mobile_sidebar_folder_expand" class="disable-select">keyboard_arrow_right
                                    </mat-icon>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </cdk-virtual-scroll-viewport>
            </div>
            <mat-divider></mat-divider>
            <div class="subfolder-header disable-select">
                <span class="subfolder-title">{{ 'YOUR_FOLDERS' | translate }}</span>
            </div>
            <div id="mobile_sidebar_folder_rootfolderlist" class="sidebar-list disable-select user-folders" *ngIf="rootUserFolders">
                <cdk-virtual-scroll-viewport class="user-folders-list sub-folders-inner-block" itemSize="30" style="height: 40vh;">
                    <mat-list>
                        <ng-container *cdkVirtualFor="let folder of rootUserFolders | vpFolderNameSearch : searchText">
                            <mat-list-item (click)="routeToFolder(folder)" vpLongPress [timeout]="500"
                                (onLongPress)="$event.stopPropagation();openActionList(folder)">
                                <div class="folder-icon disable-select">
                                    <mat-icon [style.color]="folder.rgb ? folder.rgb : '#808080'" class="disable-select">folder</mat-icon>
                                    <span class="mail-count"
                                        *ngIf="folder.name !== 'Drafts' && folder.u !== '0' && folder.u !== 0 && folder.u !== undefined">{{ folder.u > 99 ? '99+' : folder.u }}</span>
                                </div>
                                <div class="folder-title disable-select">
                                    <span class="disable-select"
                                        [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ mailService?.getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                                </div>
                                <div class="folder-expander disable-select" *ngIf="folder.children"
                                    (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)">
                                    <mat-icon id="mobile_sidebar_folder_expand" class="disable-select">keyboard_arrow_right
                                    </mat-icon>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </cdk-virtual-scroll-viewport>
            </div>
        </ng-container>
    </div>
    <vp-mail-operation-button (click)="createNewFolder(selectedMailFolder)" [iconType]="globalOperationIcon">
    </vp-mail-operation-button>
</div>
