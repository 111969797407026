
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="dialog">
    <div class="mail__dialog-header mobile_header">
        <div class="header_lbl disable-select">
            {{ 'EMAIL_HISTORY_LBL' | translate }}
        </div>
    </div>
    <div class="mail__dialog-header desktop_view">
        <div class="header_lbl disable-select">
            {{ 'EMAIL_HISTORY_LBL' | translate }}
        </div>
        <div class="close">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body mail__dialog-body--pad">

        <span class="show_desktop">
            <div class="history_for">
                <div class="lbl">
                    {{ 'EMAIL_HISTORY_FOR' | translate }}:
                </div>
                <div class="subject">
                    &nbsp;&nbsp;“{{message.su}}”
                </div>
            </div>

            <div class="sender">
                <div class="lbl">
                    {{ 'EMAIL_HISTORY_SENDER' | translate }}:
                </div>
                <div>
                    &nbsp;&nbsp; {{fromEmail}}
                </div>
            </div>
        </span>

        <span class="show_mobile">
            <div class="subject">
                “{{message.su}}”
            </div>
            <div class="sender">
                <div class="lbl">
                    {{ 'EMAIL_HISTORY_SENDER' | translate }}:
                </div>
                <div>
                    &nbsp;&nbsp; {{fromEmail}}
                </div>
            </div>
            <mat-divider></mat-divider>
        </span>

        <div *ngIf="historyViewType==='SHOW_HISTORY'">
            <span class="show_desktop">
                <table>
                    <tr class="heading">
                        <th>{{ 'EMAIL_HISTORY_DATE' | translate }}</th>
                        <th> {{ 'EMAIL_HISTORY_RECEIVER' | translate }}</th>
                        <th>{{ 'EMAIL_HISTORY_FORWARDED_TO' | translate }}</th>
                        <th>{{ 'EMAIL_HISTORY_EVENT' | translate }}</th>
                    </tr>
                    <tr *ngFor="let list of historyList ; index as i;">
                        <td *ngIf="currentLocale === 'en'"> {{list.logtime | date:'mediumDate'}}, {{list.logtime | date:'shortTime'}}</td>
                        <td *ngIf="currentLocale !== 'en'"> {{list.logtime | date: 'dd.MM.yyyy' : '': 'de'}}, {{list.logtime | date:'shortTime': '': 'de'}} </td>
                        <td> {{list.displayName}} <br> {{list.to}}</td>
                        <td> {{list.forward}}</td>
                        <td>
                            <span *ngIf="list.event === '1'">{{ 'EMAIL_HISTORY_SENT' | translate }}</span>
                            <span *ngIf="list.event === '2'">{{ 'EMAIL_HISTORY_READ' | translate }}</span>
                            <span *ngIf="list.event === '3'">{{ 'EMAIL_HISTORY_DELETED' | translate }}</span>
                            <span *ngIf="list.event === '4'">{{ 'EMAIL_HISTORY_FORWARD' | translate }}</span>
                            <span *ngIf="list.event === '5'">{{ 'EMAIL_HISTORY_DELIVERY_FAIL' | translate }}</span>
                        </td>
                    </tr>
                </table>
            </span>
            <span class="show_mobile">
                <div *ngFor="let list of historyList ; index as i;" class="history_render">
                    <span class="flex_display">
                        <div class="history_lbl">
                            {{ 'EMAIL_HISTORY_DATE' | translate }}:
                        </div>
                        <div class="history_data">
                            {{list.logtime | date:'d.MM.y, h:mm:ss'}}
                        </div>
                    </span>
                    <span class="flex_display">
                        <div class="history_lbl">
                            {{ 'EMAIL_HISTORY_RECEIVER' | translate }}:
                        </div>
                        <div class="history_data">
                            {{list.to}}
                        </div>
                    </span>
                    <span class="flex_display">
                        <div class="history_lbl">
                            {{ 'EMAIL_HISTORY_FORWARDED_TO' | translate }}:
                        </div>
                        <div class="history_data">
                            {{list.forward}}
                        </div>
                    </span>

                    <span class="flex_display">
                        <div class="history_lbl">
                            {{ 'EMAIL_HISTORY_EVENT' | translate }}:
                        </div>
                        <div class="history_data">
                            <span *ngIf="list.event === '1'">{{ 'EMAIL_HISTORY_SENT' | translate }}</span>
                            <span *ngIf="list.event === '2'">{{ 'EMAIL_HISTORY_READ' | translate }}</span>
                            <span *ngIf="list.event === '3'">{{ 'EMAIL_HISTORY_DELETED' | translate }}</span>
                            <span *ngIf="list.event === '4'">{{ 'EMAIL_HISTORY_FORWARD' | translate }}</span>
                            <span *ngIf="list.event === '5'">{{ 'EMAIL_HISTORY_DELIVERY_FAIL' | translate }}</span>
                        </div>
                    </span>
                    <mat-divider></mat-divider>
                </div>
            </span>
        </div>
        <div *ngIf="historyViewType==='NO_RESULT_FOUND'" class="no_data_found">
            {{ 'EMAIL_HISTORY_NO_DATA_FOUND' | translate }}
        </div>
        <div *ngIf="historyViewType==='NOT_AUTHORIZE'">
            <div class="not_authorize">
                <div class="icon">
                    <mat-icon class="mdi-48px" fontSet="mdi" fontIcon="mdi-eye-off-outline"></mat-icon>
                </div>
                <div class="content">
                    {{ 'EMAIL_HISTORY_NOT_ALLOWED_VIEW' | translate }}
                </div>
            </div>
        </div>
    </div>
    <mat-divider class="mobile_divider"></mat-divider>
    <div class="footer">
        <button mat-button [disableRipple]=true (click)="close()">
            <span>{{ 'CANCEL' | translate }}</span>
        </button>
        <button mat-button [disableRipple]=true [disabled]="historyViewType==='NOT_AUTHORIZE' || historyViewType==='NO_RESULT_FOUND'"
            (click)="printHistory()">
            <span>{{'MAIL_PRINT_ACTION' | translate }}</span>
        </button>
    </div>
</div>