
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="type !== 'mail-email-template-avatar'" class="vnc-avatar avatar" [ngClass] = "type" [ngStyle]="{'background-color': getColor()}">
  <img draggable="false" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (error)="imgLoadOnError($event)">
  <div *ngIf="user && user.firstLastCharacters && !avatarURL" class="profile-avtar">{{user.firstLastCharacters}}</div>
</div>
<div *ngIf="type == 'mail-email-template-avatar'" class="vnc-avatar avatar" [ngClass] = "type" [ngStyle]="{'background-color': '#9d46ff'}">
  <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-email-variant"></mat-icon>
</div>
<div class="right-bottom" *ngIf="rightIconDefaultAvatarTemplate">
  <ng-container [ngTemplateOutlet]="rightIconDefaultAvatarTemplate"></ng-container>
</div>

