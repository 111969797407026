
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        {{ 'CONFLICTS' | translate }}
    </div>
    <div class="content">
       
        <ng-container *ngIf="locations.length > 0">
            <p>{{ 'CONFLICTS_RESOURCES' | translate }}</p>

            <div class="usr" *ngFor="let userItem of locations">
                <div class="left">
                    <span>
                        <mat-icon>event</mat-icon>
                    </span>
                    <span>
                        {{ startDate | date: "mediumDate"}} {{ 'FROM' | translate }} {{ startDate | date: "HH:mm aa"}} {{ 'CALENDARS.TO_TEXT_LBL' | translate }} {{ endDate | date: "HH:mm aa"}}
                    </span>
                </div>
                <div class="right">
                    {{userItem}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="users.length > 0">
            <p>{{ 'CONFLICTS_ATTENDEES' | translate }}</p>
            <div class="usr" *ngFor="let userItem of users">
                <div class="left">
                    <mat-icon>event</mat-icon>
                    <span>
                        {{ startDate | date: "mediumDate"}} {{ 'FROM' | translate }} {{ startDate | date: "HH:mm aa"}} {{ 'CALENDARS.TO_TEXT_LBL' | translate }} {{ endDate | date: "HH:mm aa"}}
                    </span>
                </div>
                <div class="right">
                    {{userItem}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="footer">
        <button mat-button (click)="close('yes')">{{'COMMON.SAVE' | translate }}</button>
        <button mat-button (click)="close('no')">{{'COMMON.CANCEL' | translate }}</button>
    </div>
</div>