
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject, Observable, of as observableOf } from "rxjs";
import { takeUntil, take, debounceTime } from "rxjs/operators";
import { CalendarFolder, CalendarFolderFlatNode } from "src/app/common/models/calendar.model";
import { FlatTreeControl } from "@angular/cdk/tree";
import { CalendarConstants } from "src/app/common/utils/calendar-constants";
import { TranslateService } from "@ngx-translate/core";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { ToastService } from "src/app/common/providers/toast.service";
import { CreateCalendarFolderComponent } from "src/app/calendar/calendar-create-folder/calendar-create-folder.component";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { CommonService } from "src/app/services/ common.service.";
import { getCalendarFolders } from "src/app/reducers";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VncLibraryService } from "vnc-library";
import { MailService } from 'src/app/mail/shared/services/mail-service';
import { FormControl } from "@angular/forms";

@Component({
    selector: "vp-move-calendar-dialog",
    templateUrl: "./move-calendar-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MoveCalendarDialogComponent implements OnInit, OnDestroy {
    CalendarConstants = CalendarConstants;
    private isAlive$ = new Subject<boolean>();
    sourceFolder: CalendarFolder;
    calendarFolders: CalendarFolder[] = [];
    calendarTreeControl: FlatTreeControl<CalendarFolderFlatNode>;
    calendarTreeFlattener: MatTreeFlattener<CalendarFolder, CalendarFolderFlatNode>;
    calendarDataSource: MatTreeFlatDataSource<CalendarFolder, CalendarFolderFlatNode>;
    selectedFolder: CalendarFolder;
    isEventMove: boolean = false;

    searchControl: FormControl = new FormControl("", []);
    selectedCalenderFolder: any;
    rootCalendarFolders: any[] = [];
    currentSelected:any;

    constructor(
        private vncLibraryService: VncLibraryService,
        private store: Store<CalendarState>,
        public dialogRef: MatDialogRef<MoveCalendarDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectionRef: ChangeDetectorRef,
        private translate: TranslateService,
        private commonService: CommonService,
        private toastService: ToastService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private calendarRepository: CalendarRepository,
        public mailService: MailService
    ) {
        this.calendarTreeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.calendarTreeControl = new FlatTreeControl<CalendarFolderFlatNode>(this._getLevel, this._isExpandable);
        this.calendarDataSource = new MatTreeFlatDataSource(this.calendarTreeControl, this.calendarTreeFlattener);
        this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res.length > 0) {
                this.calendarFolders = res;
                this.rootCalendarFolders = res;
                console.log("calendarFolders: ", this.calendarFolders);
                if (this.calendarDataSource) {
                    this.calendarDataSource.data = this.generateTree(this.calendarFolders);
                }
                this.changeDetectionRef.markForCheck();
                this.calendarTreeControl.expandAll();
            }
        });
        this.sourceFolder = this.data.folder;
        if (this.data.isEventMove) {
            this.isEventMove = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    private _getLevel = (node: CalendarFolderFlatNode) => node.level;

    private _isExpandable = (node: CalendarFolderFlatNode) => node.expandable;

    private _getChildren(node: CalendarFolder): Observable<CalendarFolder[]> {
        let folders = node.folder || [];
        if (node.link) {
            folders = [...folders, ...node.link.map(v => v as CalendarFolder)];
        }
        return observableOf(folders);
    }

    hasChild = (_data: number, _nodeData: CalendarFolderFlatNode) => _nodeData.expandable;

    folderSelect(node: CalendarFolder): void {
        this.selectedFolder = node;
        this.changeDetectionRef.markForCheck();
    }

    ngOnInit() {
        this.searchControl.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(value => {
            this.filterChanged(value);
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.isAlive$.unsubscribe();
    }

    close() {
        this.dialogRef.close();
    }

    hasNestedChild = (_: number, nodeData: CalendarFolder) => (nodeData.folder && nodeData.folder.length > 0) || nodeData.link;
    transformer = (node: CalendarFolder, level: number) => {
        const flat = node as CalendarFolderFlatNode;
        flat.level = level;
        flat.expandable = !!node.folder;
        return flat;
    }

    filterChanged(filterText: string) {
        this.selectedFolder = undefined;
        if (filterText !== "") {
            const allFolders = [...CalenderUtils.getChildFolders(this.calendarFolders), ...this.calendarFolders];
            this.calendarDataSource.data = this.generateTree(
                allFolders.filter(f => f.name.toLowerCase().includes(filterText.toLowerCase()))
            );
        } else {
            this.calendarDataSource.data = this.generateTree(this.calendarFolders);
        }
        this.calendarTreeControl.expandAll();
        this.changeDetectionRef.markForCheck();
    }

    moveAction(): void {
        if (this.currentSelected) {
            this.selectedFolder = this.currentSelected;
        }
        // console.log("moveAction - selectedFolder: ", this.selectedFolder);
        if (this.selectedFolder === undefined || this.selectedFolder === null || this.selectedFolder.id === "1") {
            this.toastService.show("CALENDARS.MUST_SELECT_CALENDAR");
            return;
        }
        if (this.data.recover) {
            this.dialogRef.close({destinationFolder: this.selectedFolder});
            return;
        }
        if (!this.isEventMove) {
            if (
                (this.selectedFolder.folder &&
                    this.selectedFolder.folder.find(f => f.name === this.sourceFolder.name))
                    || this.selectedFolder.id === this.sourceFolder.id
            ) {
                this.toastService.show("DUPLICATE_FOLDER_MOVE_MSG");
                return;
            }
        }
        if (this.isEventMove) {
            if (this.selectedFolder.id === this.sourceFolder.id) {
                this.toastService.show("CALENDARS.SOURCE_DESTINATION_MOVE_SAME_MSG");
                return;
            }
        }
        if (!this.isEventMove) {
            const body = { id: this.sourceFolder.id, l: this.selectedFolder.id, op: "move" };
            this.commonService.folderAction(body).pipe(take(1)).subscribe(res => {
                let transatedText: string;
                this.translate.get("CALENDARS.CALENDAR_MOVE_TO_MSG",
                { sourceFolder: this.sourceFolder.name,
                    destinationFolder: this.selectedFolder.name
                }).pipe(take(1))
                .subscribe((text: string) => {
                    transatedText = text;
                });
                this.vncLibraryService.openSnackBar(transatedText, "checkmark",
                "", "", 2000, "bottom","left").subscribe(res => {
                });
                this.calendarRepository.getUserCalendarFolders();
                this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
            this.dialogRef.close({destinationFolder: this.selectedFolder});
        }
    }


    generateTree(folders: CalendarFolder[]): any {
        let allFolders: CalendarFolder[] = [];
        let translatetion: string = "";
        this.translate.get("CALENDARS.CALENDARS").pipe(take(1)).subscribe((res: string) => {
            translatetion = res;
        });
        const allFolder = [{
            name: translatetion,
            rgb: "#f8f8f8",
            folder: folders,
            id: "1",
            l: "1",
            originalFolder: [],
            absFolderPath : "/calendar"
        }];
        return [...allFolder];
    }

  getParentFolder() {
    let folderId = this.selectedCalenderFolder.id;
    for(let i = 0; i < this.calendarFolders.length; i++) {
        if(this.calendarFolders[i].id === folderId) {
            this.selectedCalenderFolder = null;
        } else if (this.calendarFolders[i].folder && this.calendarFolders[i].folder.length) {
            this.findAndSelectSubFolder(folderId, this.calendarFolders[i], i);
        }
    }
  }

  findAndSelectSubFolder(folderId: any, subFolders: any, i?:number): void {
    subFolders.folder.forEach((subFolder: any, index) => {
        if (subFolder.id === folderId) {
            this.selectedCalenderFolder = subFolders;
        } else if (subFolder.folder) {
            this.findAndSelectSubFolder(folderId, subFolder,index);
        }
        });
    }

  getChildrens(folder: any) {
    this.selectedCalenderFolder = folder;
  }

selectCurrent(folder) {
    if(!folder.perm && folder.id !== '3' ) {
        this.currentSelected = folder;
    } else {
        this.currentSelected = null;
    }
}
  createFolderNew(): void {
    console.log("this.selectedFolder", this.currentSelected)
    if (this.currentSelected === undefined || this.currentSelected === null) {
        this.currentSelected = null;
    }
    if (this.currentSelected !== null && this.currentSelected.id === "1") {
        this.currentSelected = null;
    }
    this.matDialog.open(CreateCalendarFolderComponent, {
        width: "480px",
        height: "200px",
        autoFocus: true,
        panelClass: "mail_folder-create_dialog",
        data: { targetFolder: this.currentSelected }
    });
}
}
