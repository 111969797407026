
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { Router } from "@angular/router";
import { CommonUtils } from "../../../common/utils/common-util";
import { PreferenceService } from "../../../preference/shared/services/preference.service";
import { getZimbraFeatures, getAvailableZimlets } from "src/app/reducers";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { SearchState } from "src/app/reducers/search";
import { AppState } from "src/app/reducers/app";
import { MailRootState } from "src/app/mail/store";
import { PreferenceState } from "src/app/preference/store/reducers";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { AppearanceDialogComponent } from "../appearance-dialog/appearance-dialog.component";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";

@Component({
  selector: "vp-preferences-component",
  templateUrl: "./preferences.component.html"
})
export class PreferencesComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  penIcon = CommonUtils.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtils.getFullUrl("/assets/img/filter.svg");
  preferenceTitle = "PREFERENCES_LBL";
  isSharingFeatureEnabled: boolean = true;
  isMailFilterFeatureEnabled: boolean = true;
  isOutOfOfficeFeatureEnabled: boolean = true;
  isBriefcaseNotificationEnabled: boolean = false;
  hideTrustedAddressesFromSettings: boolean = false;
  themeChangeRestricted: boolean = false;
  isContactSettingEnabled: boolean = true;
  calendarOnly = environment.calendarOnly;
  constructor(
    private commonRepository: CommonRepository,
    private router: Router,
    private preferenceService: PreferenceService,
    private config: ConfigService,
    private mailBroadcaster: MailBroadcaster,
    private store: Store<SearchState | AppState | MailRootState | PreferenceState>,
    private matDialog: MatDialog
  ) {
    this.preferenceService.setPreferenceTitle(this.preferenceTitle);
    this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.isSharingFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_SHARING_ENABLED);
      this.isMailFilterFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_FILTER_ENABLED);
      this.isOutOfOfficeFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_OUT_OF_OFFICE_ENABLED);
    });
    this.store.select(getAvailableZimlets).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res && res.length > 0) {
        this.isBriefcaseNotificationEnabled = MailUtils.isZimletEnabledOrMendatory(res, "biz_vnc_briefcase_notification");
      }
    });
    this.hideTrustedAddressesFromSettings = this.config.get("hideTrustedAddressesFromSettings");
    if (environment.theme === "dfb") {
      this.themeChangeRestricted = true;
    }
    this.isContactSettingEnabled = !this.config.useVNCdirectoryAuth;
  }

  navigate(url) {
    if (url !== "signatures") {
      if (this.commonRepository.showNoInternetToastIfRequired()) {
        return;
      }
    }

    this.router.navigate(["/preferences", url]);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  startMobileResync() {
    const oldLastMessagesTimestamp = localStorage.getItem("lastMessagesTimestamp");
    if (!oldLastMessagesTimestamp) {
      const oneWeekAgo = Date.now() - 604800000;
      localStorage.setItem("lastMessageTimestamp", "" + oneWeekAgo);
      this.mailBroadcaster.broadcast("STARTRESYNC");
    }
  }

  changeAppearance(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.matDialog.open(AppearanceDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail-apprearance-dialog"
    });
  }

}
