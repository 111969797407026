
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="content">
    <!-- Instance or Series Appointment selection -->
    <mat-list *ngIf="isRepeatAppointment" class="instance-series-item">
        <mat-list-item (click)="instanceSelect()" class="disable-select">
            <span>
                {{"CALENDARS.INSTANCE" | translate}}
            </span>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </mat-list-item>
        <mat-list-item (click)="seriesSelect()" class="disable-select">
            <span>{{"CALENDARS.SERIES" | translate}}</span>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </mat-list-item>
        <mat-list-item (click)="close()" class="disable-select">
            <span>{{"COMMON.CANCEL" | translate}}</span>
            <mat-icon>close</mat-icon>
        </mat-list-item>
    </mat-list>

    <!-- Simple Appointment selection-->
    <mat-list *ngIf="isSimpleAppointment" class="simple-menu">
        <mat-list-item (click)="submit('simple', 'open')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            <span>{{ "CALENDARS.OPEN" | translate }}</span>
        </mat-list-item>
        <mat-list-item (click)="submit('simple', 'print')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            <span>{{ "CALENDARS.PRINT" | translate }}</span>
        </mat-list-item>
        <mat-list-item *ngIf="!isReadOnlyCalendar && !!event && event.ptst && !event.isOrganizer" (click)="submit('simple', 'ACCEPT')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span>{{ "ACCEPT_LBL" | translate }}</span>
        </mat-list-item>
        <mat-list-item *ngIf="!isReadOnlyCalendar && !!event && event.ptst && !event.isOrganizer" (click)="submit('simple', 'TENTATIVE')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-help"></mat-icon>
            <span>{{ "TENTATIVE_LBL" | translate }}</span>
        </mat-list-item>
        <mat-list-item *ngIf="!isReadOnlyCalendar && !!event && event.ptst && !event.isOrganizer" (click)="submit('simple', 'DECLINE')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "DECLINE_LBL" | translate }}</span>
        </mat-list-item>
        <mat-list-item *ngIf="!isReadOnlyCalendar && !!event && event.otherAtt && event.isOrganizer" (click)="submit('simple', 're-Invite')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-text-outline"></mat-icon>
            <span>{{ "RE_INVITE_ATTENDEES" | translate }}</span>
        </mat-list-item>
        <mat-list-item (click)="submit('simple', 'create-copy')" class="disable-select">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy"></mat-icon>
            <span>{{ "CALENDARS.CREATE_A_COPY" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="appointmentContextMenuActions.enableReply" (click)="submit('simple', 'reply')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-reply"></mat-icon>
            <span>{{ "CALENDARS.REPLY" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="appointmentContextMenuActions.enableReplyAll" (click)="submit('simple', 'reply-all')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-reply-all"></mat-icon>
            <span>{{ "CALENDARS.REPLY_TO_ALL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="appointmentContextMenuActions.enableForward" (click)="submit('simple', 'forward')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-forward"></mat-icon>
            <span>{{ "CALENDARS.FORWARD" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="appointmentContextMenuActions.cancelAction" (click)="submit('simple', 'cancel')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "COMMON.CANCEL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="!appointmentContextMenuActions.cancelAction && appointmentContextMenuActions.enableDelete" (click)="submit('simple', 'delete')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
            <span>{{ "CALENDARS.DELETE" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.moveAction" (click)="submit('simple', 'move')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-folder-move"></mat-icon>
            <span>{{ "CALENDARS.MOVE" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.enableTag" (click)="submit('simple', 'tag')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
            <span>{{ "CALENDARS.TAG_APPOINTMENT" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('simple', 'show-original')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-text"></mat-icon>
            <span>{{ "CALENDARS.SHOW_ORIGINAL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="close()">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "COMMON.CLOSE_MENU" | translate }}</span>
        </mat-list-item>
    </mat-list>

    <!--Series Appointment selection -->
    <mat-list *ngIf="isSeriesAppointment" class="simple-menu">
        <mat-list-item class="disable-select" (click)="submit('series', 'open')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            <span>{{ "CALENDARS.OPEN_SERIES" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('series', 'print')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            <span>{{ "CALENDARS.PRINT" | translate }}</span>
        </mat-list-item>
        <mat-list-item *ngIf="seriesContextMenuActions.enableReInvite" class="disable-select" (click)="submit('series', 're-invite')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-text-outline"></mat-icon>
            <span>{{ "RE_INVITE_ATTENDEES" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('series', 'create-copy')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy"></mat-icon>
            <span>{{ "CALENDARS.CREATE_A_COPY" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.enableReply" (click)="submit('series', 'reply')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-reply"></mat-icon>
            <span>{{ "CALENDARS.REPLY" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.enableReplyAll" (click)="submit('series', 'reply-all')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-reply-all"></mat-icon>
            <span>{{ "CALENDARS.REPLY_TO_ALL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.enableForward" (click)="submit('series', 'forward')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-forward"></mat-icon>
            <span>{{ "CALENDARS.FORWARD_SERIES" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="appointmentContextMenuActions.cancelAction" (click)="submit('series', 'cancel')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "COMMON.CANCEL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.enableDelete" (click)="submit('series', 'delete')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
            <span>{{ "CALENDARS.DELETE_SERIES" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.moveAction" (click)="submit('series', 'move')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-folder-move"></mat-icon>
            <span>{{ "CALENDARS.MOVE" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="seriesContextMenuActions.enableTag" (click)="submit('series', 'tag')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
            <span>{{ "CALENDARS.TAG_APPOINTMENT" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('series', 'show-original')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-text"></mat-icon>
            <span>{{ "CALENDARS.SHOW_ORIGINAL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="close()">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "COMMON.CLOSE_MENU" | translate }}</span>
        </mat-list-item>
    </mat-list>

    <!--Instance Appointment selection -->
    <mat-list *ngIf="isInstanceAppointment" class="simple-menu">
        <mat-list-item class="disable-select" (click)="submit('instance', 'open')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            <span>{{ "CALENDARS.OPEN_INSTANCE" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('instance', 'print')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            <span>{{ "CALENDARS.PRINT" | translate }}</span>
        </mat-list-item>
        <mat-list-item *ngIf="instanceContextMenuActions.enableReInvite" class="disable-select" (click)="submit('instance', 're-invite')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-text-outline"></mat-icon>
            <span>{{ "RE_INVITE_ATTENDEES" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('instance', 'create-copy')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy"></mat-icon>
            <span>{{ "CALENDARS.CREATE_A_COPY" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="instanceContextMenuActions.enableReply" (click)="submit('instance', 'reply')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-reply"></mat-icon>
            <span>{{ "CALENDARS.REPLY" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="instanceContextMenuActions.enableReplyAll" (click)="submit('instance', 'reply-all')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-reply-all"></mat-icon>
            <span>{{ "CALENDARS.REPLY_TO_ALL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="instanceContextMenuActions.enableForward" (click)="submit('instance', 'forward')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-forward"></mat-icon>
            <span>{{ "CALENDARS.FORWARD_INSTANCE" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="appointmentContextMenuActions.cancelAction" (click)="submit('instance', 'cancel')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "COMMON.CANCEL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="instanceContextMenuActions.enableDelete" (click)="submit('instance', 'delete')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
            <span>{{ "CALENDARS.DELETE_INSTANCE" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" *ngIf="instanceContextMenuActions.enableTag" (click)="submit('instance', 'tag')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
            <span>{{ "CALENDARS.TAG_APPOINTMENT" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="submit('instance', 'show-original')">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-text"></mat-icon>
            <span>{{ "CALENDARS.SHOW_ORIGINAL" | translate }}</span>
        </mat-list-item>
        <mat-list-item class="disable-select" (click)="close()">
            <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{ "COMMON.CLOSE_MENU" | translate }}</span>
        </mat-list-item>
    </mat-list>
</div>