
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, Input, Output, EventEmitter, ElementRef, OnInit } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

@Component({
    selector: "vp-common-dialog",
    templateUrl: "./common-dialog.component.html",
    styles: [
        `
        `
    ]
})
export class CommonDialogComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    @Input() customStyles: any;
    @Input() headerText;
    @Input() closeText: string = "close";
    @Input() hideHeader: boolean = false;
    @Input() hideBody: boolean = false;
    @Input() hideFooter: boolean = false;
    @Output() closeClicked = new EventEmitter<any>();
    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        if (!!this.el.nativeElement.closest("mat-dialog-container")) {
            this.el.nativeElement.closest("mat-dialog-container").classList.add("custom-vnc-dialog-container");
        }
        if (!!this.el.nativeElement.closest(".cdk-overlay-pane")) {
            this.el.nativeElement.closest(".cdk-overlay-pane").classList.add("custom-vnc-overlay-pane");
        }
    }
    
    close() {
        this.closeClicked.emit();
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }
}
