
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { take } from "rxjs/operators";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { CalendarAppointment } from "src/app/common/models/calendar.model";
import { CommonService } from "src/app/services/ common.service.";
import { getOnlineStatus, RootState } from "src/app/reducers";
import { UpdateCalendarAppointmentSuccessAction } from "src/app/actions/calendar.actions";
import { ToastService } from "src/app/common/providers/toast.service";
import { forkJoin, Observable, Subject, timer } from "rxjs";
import * as moment from "moment-timezone";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "src/app/config.service";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { Router } from "@angular/router";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { DatabaseService } from "src/app/services/db/database.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

@Component({
    selector: "vp-calender-appointment-reminder-dialog",
    templateUrl: "./appointment-reminder.component.html"
})

export class AppointmentReminderDialogComponent implements OnInit {
    listoptions: any[] = [];
    snoozeAllItem: any = "1-MINUTE";
    currentLocale: string = "en";
    isOnline = false;
    constructor(
        public commonService: CommonService,
        private calendarRepository: CalendarRepository,
        private store: Store<RootState>,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private translateService: TranslateService,
        private configService: ConfigService,
        private commonRepository: CommonRepository,
        private broadcaster: MailBroadcaster,
        private router: Router,
        private databaseService: DatabaseService
    ) {
        this.overDueCountCall();
        timer(0, 60000).subscribe(val => {
            this.overDueCountCall();
        });

        this.store.select(getOnlineStatus).subscribe(res => {
          this.isOnline = res;
        });
    }

    ngOnInit() {
        this.listoptions = this.calendarRepository.reminderOptionsWithoutBefore();
    }

    open(): void {

    }

    dismissAppointmentReminder(app: any): void {
        const dismissOn = new Date().getTime();
        const appointmentId = app.id.split("_")[0];
        if (this.isOnline) {
            this.commonService.dismissApptRequest(appointmentId, dismissOn).pipe(take(1)).subscribe(res => {
                this.updateEventAlarmListAfterAction(res, "dismiss");
                this.databaseService.getAppointmentsById(app.id).subscribe(resp => {
                  if (!!resp) {
                    let appt: any = resp;
                    appt.alarmData = undefined;
                    this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                  }
                });
                this.updateEventAlarmActiveList(appointmentId);
                this.updateEventAlarmActiveList(app.id);
                app.alarmData = undefined;
                this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: app.eventId, changes: app }));
            }, error => {
                console.error("[dismissApptRequest] error", error);
                this.toastService.showPlainMessage(error);
            });
        } else {

            let requestBody = {
                DismissCalendarItemAlarmRequest: {
                  "@": {
                    xmlns: "urn:zimbraMail"
                  },
                  "appt": {
                    id: appointmentId,
                    dismissedAt: dismissOn
                  }
                }
              };;

            const request = {
                "url": "/api/batchRequest",
                "method": "post",
                "body":  requestBody
            };

            this.databaseService.addPendingOperation(appointmentId, "batchRequest", request).subscribe(res => {
                this.databaseService.getAppointmentsById(app.id).subscribe(resp => {
                  if (!!resp) {
                    let appt: any = resp;
                    appt.alarmData = undefined;
                    this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                  }
                });
                this.updateEventAlarmActiveList(appointmentId);
                this.updateEventAlarmActiveList(app.id);
                app.alarmData = undefined;
                this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: app.eventId, changes: app }));
            });
        }
    }

    updateEventAlarmListAfterAction(res: any, type: string, appt?: any) {
        if (type === "snooz") {
            this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: appt.eventId, changes: appt }));
        }
    }

    updateEventAlarmActiveList(apptId) {
        let apptIndex = -1;
        this.commonService.alarmEventsActive.forEach((appt, indx) => {
            if (appt.id === apptId) {
                apptIndex = indx;
            }
        });
        if (apptIndex !== -1) {
            this.commonService.alarmEventsActive.splice(apptIndex, 1);
        }
        if (this.commonService.alarmEventsActive.length === 0) {
            this.commonService.isEventReminderActivated = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    snoozAppointment(app: any): void {
        let newAlarmTimes = this.getAlarmTime(app);
        app.alarmData[0].nextAlarm = newAlarmTimes;
        const appointmentId = app.id.split("_")[0];
        if (this.isOnline) {
            this.commonService.snozeApptRequest(appointmentId, newAlarmTimes).pipe(take(1)).subscribe(res => {
                this.databaseService.getAppointmentsById(app.id).subscribe(resp => {
                    if(resp) {
                        let appt: any = resp;
                        appt['alarmData'][0].nextAlarm = newAlarmTimes;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                    }
                });
                this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: app.eventId, changes: app }));
                this.updateEventAlarmActiveList(appointmentId);
                this.updateEventAlarmActiveList(app.id);
            }, error => {
                console.error("[snozeApptRequest] error", error);
                this.toastService.showPlainMessage(error);
            });
        } else {
            let requestBody = {
                SnoozeCalendarItemAlarmRequest: {
                  "@": {
                    xmlns: "urn:zimbraMail"
                  },
                  "appt": {
                    id: appointmentId,
                    until: newAlarmTimes
                  }
                }
              };

            const request = {
                "url": "/api/batchRequest",
                "method": "post",
                "body":  requestBody
            };

            this.databaseService.addPendingOperation(appointmentId, "batchRequest", request).subscribe(res => {
                this.databaseService.getAppointmentsById(app.id).subscribe(resp => {
                    let appt: any = resp;
                    try {
                        appt.alarmData[0].nextAlarm = newAlarmTimes;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                    } catch {
                        this.calendarRepository.saveAppointmentRequestApplyToDB(app).subscribe(() => {});
                    }
                });

                this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: app.eventId, changes: app }));
                this.updateEventAlarmActiveList(appointmentId);
                this.updateEventAlarmActiveList(app.id);
            });
        }

    }

    private getAlarmTime(app) {
        // console.log("getAlarmTime: ", app);
        const unit = app.alarmItem ? app.alarmItem.split("-")[1] : "";
        const value = app.alarmItem ? app.alarmItem.split("-")[0] : "";
        const today = new Date().getTime();
        let newAlarmTimes: number;
        if (unit === "HOURS_BEFORE" || unit === "HOURS" || unit === "HOUR") {
            newAlarmTimes = today + (value * 60 * 60 * 1000);
        } else if (unit === "MINUTE_BEFORE" || unit === "MINUTE" || unit === "MINUTES") {
            newAlarmTimes = today + (value * 60 * 1000);
        } else if (unit === "DAY_BEFORE" || unit === "DAY" || unit === "DAYS") {
            newAlarmTimes = today + (value * 60 * 24 * 60 * 1000);
        } else if (unit === "WEEK_BEFORE" || unit === "WEEK" || unit === "WEEKS") {
            newAlarmTimes = today + (value * 60 * 24 * 7 * 60 * 1000);
        } else {
            newAlarmTimes = today;
        }
        return newAlarmTimes;
    }

    snoozAllAppointment(): void {
        const app = {
          alarmItem: this.snoozeAllItem
        };
        const allAppointment = this.commonService.alarmEventsActive;
        const snoozItems: any[] = [];
        let newAlarmTimes = this.getAlarmTime(app);
        // console.log("snoozeAllItem: ", app, newAlarmTimes);
        allAppointment.map(aptmt => {
            aptmt.alarmData[0].nextAlarm = newAlarmTimes;
            snoozItems.push({
                id: aptmt.id.split("_")[0],
                until: newAlarmTimes
            });
        });
        if (this.isOnline) {
            this.commonService.snoozeAllApptRequest(snoozItems).pipe(take(1)).subscribe(res => {
                allAppointment.map( item => {
                    this.databaseService.getAppointmentsById(item.id).subscribe(resp => {
                        if(resp) {
                            let appt: any = resp;
                            appt.alarmData = item.alarmData;
                            this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                        }
                    });
                    this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: item.eventId, changes: item }));
                    this.updateEventAlarmActiveList(item.id);
                });
                this.commonService.isEventReminderActivated = false;
                this.commonService.alarmEventsActive = [];
                this.changeDetectionRef.markForCheck();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
            let requestBody = {
                SnoozeCalendarItemAlarmRequest: {
                  "@": {
                    xmlns: "urn:zimbraMail"
                  },
                  "appt": snoozItems
                }
              };

            const request = {
                "url": "/api/batchRequest",
                "method": "post",
                "body":  requestBody
            };


            // fake id
            let id = this.createFakeId("snooze_all");
            this.databaseService.addPendingOperation(id, "batchRequest", request).subscribe(res => {

                                const getDBAppts$: any = [];
                allAppointment.map( item => {
                    getDBAppts$.push(this.databaseService.getAppointmentsById(item.id));
                });

                forkJoin(getDBAppts$).subscribe((appts: any) => {
                            try {
                                appts.forEach(resp => {
                                    let appt: any = resp;
                                    appt.alarmData = allAppointment.find(x => x.id === resp.id).alarmData;
                                    this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                                });
                            } catch {
                                allAppointment.map(rr=>{
                                    this.calendarRepository.saveAppointmentRequestApplyToDB(rr).subscribe(() => {});
                                })
                            }

                    let clonnedAppts = JSON.parse(JSON.stringify(allAppointment));
                    clonnedAppts.map( item => {
                        this.updateEventAlarmActiveList(item.id);
                        this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: item.eventId, changes: item }));
                    });
                    this.commonService.isEventReminderActivated = false;
                    this.commonService.alarmEventsActive = [];
                    this.changeDetectionRef.markForCheck();
                });
            });
        }
    }

    dismissAllAppointmentReminder(): void {

        const dismissOn = new Date().getTime();
        const allAppointment = this.commonService.alarmEventsActive;
        const dismissItems: any[] = [];
        allAppointment.filter(v => !!v).map(aptmt => {
            dismissItems.push({
                id: aptmt.id.split("_")[0],
                dismissedAt: dismissOn
            });
        });
        if (this.isOnline) {
            this.commonService.dismissAllApptRequest(dismissItems).pipe(take(1)).subscribe(res => {
                allAppointment.map( item => {
                    this.databaseService.getAppointmentsById(item.id).subscribe(resp => {
                        let appt: any = resp;
                        appt.alarmData = undefined;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                    });
                    item.alarmData = undefined;
                    this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: item.eventId, changes: item }));
                });
                this.commonService.isEventReminderActivated = false;
                this.commonService.alarmEventsActive = [];
                this.changeDetectionRef.markForCheck();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
            let requestBody = {
                DismissCalendarItemAlarmRequest: {
                  "@": {
                    xmlns: "urn:zimbraMail"
                  },
                  "appt": dismissItems
                }
              };

            const request = {
                "url": "/api/batchRequest",
                "method": "post",
                "body":  requestBody
            };


            // fake id
            let id = this.createFakeId("dismiss_all");
            this.databaseService.addPendingOperation(id, "batchRequest", request).subscribe(res => {

                const getDBAppts$: any = [];
                allAppointment.map( item => {
                    getDBAppts$.push(this.databaseService.getAppointmentsById(item.id));
                });

                forkJoin(getDBAppts$).subscribe((appts: any) => {
                    console.log("appointmentremidersdismiss apts: ", appts);
                    appts.forEach(resp => {
                        let appt: any = resp;
                        console.log("appointmentremidersdismiss resp: ", resp);
                        if (!!appt && !!appt.alarmData) {
                            appt.alarmData = undefined;
                            this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                        }
                    });
                    let clonnedAppts = JSON.parse(JSON.stringify(allAppointment));
                    clonnedAppts.map( item => {
                        this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: item.eventId, changes: item }));
                    });
                    this.commonService.isEventReminderActivated = false;
                    this.commonService.alarmEventsActive = [];
                    this.changeDetectionRef.markForCheck();
                });
            });
        }
    }

    overDueCountCall(): void {
        this.configService.currentLanguage.subscribe(res => {
            if (!!res) {
              if (res === "en") {
                this.currentLocale = "en";
              } else {
                this.currentLocale = "de";
              }
            } else {
              this.currentLocale = "en";
            }
            if (this.currentLocale !== "en") {
                registerLocaleData(localeDE);
            } else {
                registerLocaleData(localEN);
            }
            this.changeDetectionRef.markForCheck();
        });
        if (!!this.commonService.alarmEventsActive && this.commonService.alarmEventsActive.length > 0 ) {
            console.log("calendarReminderActive: ", this.commonService.alarmEventsActive)
            this.commonService.alarmEventsActive.map( appt => {
                let deltaTime = null;
                const startTime = moment(appt.start).toDate().getTime();
                const endTime = moment(appt.end).toDate().getTime();
                const now = (new Date()).getTime();
                if (!appt.alarmData || appt.alarmData.length === 0) {
                    deltaTime = now - startTime;
                } else {
                    const alarmInstStart = appt.alarmData[0].nextAlarm;
                    try {
                        console.log("calendarReminderAppt: ", appt);
                        if (alarmInstStart) {
                            deltaTime = now - startTime;
//                        } else if (!!appt && !!appt.getEndTime()) {
//                            deltaTime = now - endTime;
                        }
                    } catch (error) {
                        console.error("remindererror: ", error);
                        return;
                    }
                }
                const formatDalta = CalenderUtils.formatDeltaString(deltaTime, appt.allDay);
                appt.reminderTimeCount = "";
                if (formatDalta.key) {
                    if (formatDalta.key === "reminderOverdueByHoursMinutes") {
                        const args = formatDalta.args;
                        const hours = args[4];
                        const minutes = args[5];
                        if (hours === 0) {
                            const mint: number = minutes;
                            if (mint > 1) {
                                this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_MINUTES", { minutes: minutes })
                                .pipe(take(1)).subscribe((text: string) => {
                                    appt.reminderTimeCount = text;
                                });
                            } else {
                                this.translateService.get("CALENDARS.OVER_DUE_BY_MINUTES", { minutes: minutes })
                                .pipe(take(1)).subscribe((text: string) => {
                                    appt.reminderTimeCount = text;
                                });
                            }
                        } else {
                            const hr: number = hours;
                            if (hr > 1) {
                                this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_HOURS_MINUTES", { hours: hours, minutes: minutes })
                                .pipe(take(1)).subscribe((text: string) => {
                                    appt.reminderTimeCount = text;
                                });
                            } else {
                                if (minutes > 1) {
                                    this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_HOUR_MINUTES", { hours: hours, minutes: minutes })
                                    .pipe(take(1)).subscribe((text: string) => {
                                        appt.reminderTimeCount = text;
                                    });
                                } else {
                                    this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_HOUR_MINUTE", { hours: hours, minutes: minutes })
                                    .pipe(take(1)).subscribe((text: string) => {
                                        appt.reminderTimeCount = text;
                                    });
                                }
                            }
                        }
                    } else if (formatDalta.key === "reminderOverdueByYears") {
                        const args = formatDalta.args;
                        const years = args[1];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_YEARS", { years: years })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByYearsMonths") {
                        const args = formatDalta.args;
                        const years = args[1];
                        const month = args[2];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_YEARS_MONTHS", { years: years, month: month })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByMonths") {
                        const args = formatDalta.args;
                        const month = args[2];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_MONTH", { month: month })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByMonthsDays") {
                        const args = formatDalta.args;
                        const month = args[2];
                        const day = args[3];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_MONTH_DAY", { month: month, day: day })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderOverdueByDays") {
                        const args = formatDalta.args;
                        const day = args[3];
                        if (day > 1) {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_DAYS", { day: day }).pipe(take(1))
                            .subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        } else {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_DAYS", { day: day }).pipe(take(1))
                            .subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        }
                } else if (formatDalta.key === "reminderOverdueByDaysHours") {
                        const args = formatDalta.args;
                        const day = args[3];
                        const hours = args[4];
                        this.translateService.get("CALENDARS.OVER_DUE_BY_DAY_HOURS", { day: day, hours: hours })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderOverdueByToday") {
                        const args = formatDalta.args;
                        const day = args[3];
                        this.translateService.get("CALENDARS.DUE_TODAY").pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderOverdueByMinutes") {
                        const args = formatDalta.args;
                        const minutes = args[5];
                        if (minutes > 1) {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_MORE_MINUTES", { minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        } else {
                            this.translateService.get("CALENDARS.OVER_DUE_BY_MINUTES", { minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        }
                    } else if (formatDalta.key === "reminderInHoursMinutes") {
                        const args = formatDalta.args;
                        const hours = args[4];
                        const minutes = args[5];
                        if (hours === 0) {
                            this.translateService.get("CALENDARS.IN_MINUTES", { minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        } else {
                            this.translateService.get("CALENDARS.IN_HOURS_MINUTES", { hours: hours, minutes: minutes })
                            .pipe(take(1)).subscribe((text: string) => {
                                appt.reminderTimeCount = text;
                            });
                        }
                    } else if (formatDalta.key === "reminderInYears") {
                        const args = formatDalta.args;
                        const years = args[1];
                        this.translateService.get("CALENDARS.IN_YEARS", { years: years }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInYearsMonths") {
                        const args = formatDalta.args;
                        const years = args[1];
                        const month = args[2];
                        this.translateService.get("CALENDARS.IN_YEARS_MONTH", { years: years, month: month })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInMonths") {
                        const args = formatDalta.args;
                        const month = args[2];
                        this.translateService.get("CALENDARS.IN_MONTHS", { month: month }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInMonthsDays") {
                        const args = formatDalta.args;
                        const month = args[2];
                        const day = args[3];
                        this.translateService.get("CALENDARS.IN_MONTHS_DAYS", { month: month, day: day })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInDays") {
                        const args = formatDalta.args;
                        const day = args[3];
                        this.translateService.get("CALENDARS.IN_DAY", { day: day }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    } else if (formatDalta.key === "reminderInDaysHours") {
                        const args = formatDalta.args;
                        const day = args[3];
                        const hours = args[4];
                        this.translateService.get("CALENDARS.IN_DAY_HOURS", { day: day, hours: hours })
                        .pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderInToday") {
                        const args = formatDalta.args;
                        const day = args[3];
                        this.translateService.get("CALENDARS.IN_TODAY").pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }  else if (formatDalta.key === "reminderInMinutes") {
                        const args = formatDalta.args;
                        const minutes = args[5];
                        this.translateService.get("CALENDARS.IN_MINUTES", { minutes: minutes }).pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }
                } else {
                    if (formatDalta === "Now") {
                        this.translateService.get("CALENDARS.IN_NOW").pipe(take(1)).subscribe((text: string) => {
                            appt.reminderTimeCount = text;
                        });
                    }
                }
            });
            this.changeDetectionRef.markForCheck();
        }
    }

    openEditEvent(app: any): void {
        const allAppointment = this.commonService.alarmEventsActive;
        const snoozItems: any[] = [];
        let newAlarmTimes: number;
        newAlarmTimes = new Date().getTime() + (5 * 60 * 1000);
        allAppointment.map(aptmt => {
            aptmt.alarmData[0].nextAlarm = newAlarmTimes;
            snoozItems.push({
                id: aptmt.id,
                until: newAlarmTimes
            });
        });
        if (this.isOnline) {
            this.commonService.snoozeAllApptRequest(snoozItems).pipe(take(1)).subscribe(res => {
                allAppointment.map( item => {

                    this.databaseService.getAppointmentsById(item.id).subscribe(resp => {
                        let appt: any = resp;
                        appt.alarmData = item.alarmData;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                    });
                    this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: item.eventId, changes: item }));
                    this.updateEventAlarmActiveList(item.id);
                });
                if (!this.router.url.startsWith("/calendar")) {
                    this.router.navigate(["/calendar"]);
                    setTimeout(() => {
                        this.broadcaster.broadcast("OPEN_CALENDAR_EVENT_FROM_REMINDER", {
                            event: app
                        });
                    }, 1500);
                } else {
                    this.broadcaster.broadcast("OPEN_CALENDAR_EVENT_FROM_REMINDER", {
                        event: app
                    });
                }
                this.commonService.isEventReminderActivated = false;
                this.commonService.alarmEventsActive = [];
                this.changeDetectionRef.markForCheck();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
            let requestBody = {
                SnoozeCalendarItemAlarmRequest: {
                  "@": {
                    xmlns: "urn:zimbraMail"
                  },
                  "appt": snoozItems
                }
              };

            const request = {
                "url": "/api/batchRequest",
                "method": "post",
                "body":  requestBody
            };


            // fake id
            let id = this.createFakeId("snooze_all");
            this.databaseService.addPendingOperation(id, "batchRequest", request).subscribe(res => {

                const getDBAppts$: any = [];
                allAppointment.map( item => {
                    getDBAppts$.push(this.databaseService.getAppointmentsById(item.id));
                });

                forkJoin(getDBAppts$).subscribe((appts: any) => {
                    appts.forEach(resp => {
                        let appt: any = resp;
                        appt.alarmData = allAppointment.find(x => x.id === resp.id).alarmData;
                        this.calendarRepository.saveAppointmentRequestApplyToDB(appt).subscribe(() => {});
                    });
                    let clonnedAppts = JSON.parse(JSON.stringify(allAppointment));
                    clonnedAppts.map( item => {
                        this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: item.eventId, changes: item }));
                        this.updateEventAlarmActiveList(item.id);
                    });
                    if (!this.router.url.startsWith("/calendar")) {
                        this.router.navigate(["/calendar"]);
                        setTimeout(() => {
                            this.broadcaster.broadcast("OPEN_CALENDAR_EVENT_FROM_REMINDER", {
                                event: app
                            });
                        }, 1500);
                    } else {
                        this.broadcaster.broadcast("OPEN_CALENDAR_EVENT_FROM_REMINDER", {
                            event: app
                        });
                    }
                    this.commonService.isEventReminderActivated = false;
                    this.commonService.alarmEventsActive = [];
                    this.changeDetectionRef.markForCheck();
                });
            });
        }
        this.commonService.isOpenedAppointment = true;
        this.broadcaster.broadcast(BroadcastKeys.HIDE_APPOINTMENT_DIALOG);
        this.broadcaster.broadcast(BroadcastKeys.HANDLE_BACK_BUTTON);
    }

    private createFakeId(data: string) {
        const current = new Date();
        return `fake#${MailUtils.md5(data)}-${current.getTime()}`;
    }
}
