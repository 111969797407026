
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="cal-week-view">
  <div
    class="cal-week-view-header"
    (swipeleft)="onViewSwipe($event)"
    (swiperight)="onViewSwipe($event)"
  >
    <vp-calendar-week-view-header
      [days]="days"
      [locale]="locale"
      [customTemplate]="headerTemplate"
      (dayHeaderClicked)="dayHeaderClicked.emit($event)"
      (eventDropped)="eventDropped({ dropData: $event }, $event.newStart, true)"
    >
    </vp-calendar-week-view-header>
    <div
      class="cal-all-day-events"
      #allDayEventsContainer
      *ngIf="view.allDayEventRows.length > 0"
      mwlDroppable
      (dragEnter)="eventDragEnter = eventDragEnter + 1"
      (dragLeave)="eventDragEnter = eventDragEnter - 1"
    >
      <div class="cal-day-columns">
          <div class="cal-time-label-column">
              <span class="all-day-title">{{ 'CALENDARS.ALL_DAY_LBL' | translate }}</span>
            </div>
        <div
          class="cal-day-column"
          [class.cal-weekend]="day.isWeekend"
          *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          mwlDroppable
          dragOverClass="cal-drag-over"
          (drop)="eventDropped($event, day.date, true)"
          (contextmenu)="hourSegmentContextMenuClicked.emit({ $event: $event, segment: day.date, allDay: true })"
        ></div>
      </div>
      <div class="week-scroll-all-day">
      <div
        *ngFor="let eventRow of view.allDayEventRows; trackBy: trackById"
        #eventRowContainer
        class="cal-events-row"
      >
        <div
          *ngFor="
            let allDayEvent of eventRow.row;
            trackBy: trackByDayOrWeekEvent
          "
          #event
          class="cal-event-container"
          [class.cal-draggable]="
            allDayEvent.event.draggable && allDayEventResizes.size === 0
          "
          [class.cal-starts-within-week]="!allDayEvent.startsBeforeWeek"
          [class.cal-ends-within-week]="!allDayEvent.endsAfterWeek"
          [ngClass]="allDayEvent.event?.cssClass"
          [style.width.%]="(100 / days.length) * allDayEvent.span"
          [style.marginLeft.%]="(100 / days.length) * allDayEvent.offset"
          mwlResizable
          [resizeSnapGrid]="{ left: dayColumnWidth, right: dayColumnWidth }"
          [validateResize]="validateResize"
          (resizeStart)="
            allDayEventResizeStarted(eventRowContainer, allDayEvent, $event)
          "
          (resizing)="allDayEventResizing(allDayEvent, $event, dayColumnWidth)"
          (resizeEnd)="allDayEventResizeEnded(allDayEvent)"
          mwlDraggable
          dragActiveClass="cal-drag-active"
          [dropData]="{ event: allDayEvent.event, calendarId: calendarId }"
          [dragAxis]="{
            x: allDayEvent.event.draggable && allDayEventResizes.size === 0,
            y:
              !snapDraggedEvents &&
              allDayEvent.event.draggable &&
              allDayEventResizes.size === 0
          }"
          [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth } : {}"
          [validateDrag]="validateDrag"
          (dragStart)="dragStarted(eventRowContainer, event)"
          (dragging)="allDayEventDragMove()"
          (dragEnd)="dragEnded(allDayEvent, $event, dayColumnWidth)"
          (dblclick)="handleDoubleClickedHandled(allDayEvent.event)"
        >
          <div
            class="cal-resize-handle cal-resize-handle-before-start"
            *ngIf="!allDayEvent.startsBeforeWeek
            "
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
          ></div>
          <vp-calendar-week-view-event
            [weekEvent]="allDayEvent"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipDelay]="tooltipDelay"
            [customTemplate]="eventTemplate"
            [eventTitleTemplate]="eventTitleTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            [refresh]="eventRefresh"
            (eventClicked)="handleAppointmentClicked(allDayEvent.event, $event.isSelecting)"
            (onContextMenuClicked)="onContextMenuClicked.emit($event)"
            (longPressClick)="longPressClicked.emit($event)"
          >
          </vp-calendar-week-view-event>
          <div
            class="cal-resize-handle cal-resize-handle-after-end"
            *ngIf="!allDayEvent.endsAfterWeek
            "
            mwlResizeHandle
            [resizeEdges]="{ right: true }"
          ></div>
        </div>
      </div>
    </div>

    </div>
  </div>
  <div
    id="weekViewCalTimeEvents"
    class="cal-time-events"
    mwlDroppable
    (dragEnter)="eventDragEnter = eventDragEnter + 1"
    (dragLeave)="eventDragEnter = eventDragEnter - 1"
    (swipeleft)="onViewSwipe($event)"
    (swiperight)="onViewSwipe($event)"
  >
    <div id="weekViewCalTimeLabelCol" class="cal-time-label-column" *ngIf="view.hourColumns.length > 0">
      <div
        *ngFor="
          let hour of view.hourColumns[0].hours;
          trackBy: trackByHour;
          let odd = odd
        "
        class="cal-hour"
        [class.cal-hour-odd]="odd"
      >
        <vp-calendar-week-view-hour-segment
          *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
          [style.height.px]="hourSegmentHeight"
          [segment]="segment"
          [segmentHeight]="hourSegmentHeight"
          [locale]="locale"
          [customTemplate]="hourSegmentTemplate"
          [isTimeLabel]="true"
          (hourSegmentContextMenuClicked)="hourSegmentContextMenuClicked.emit($event)"
        >
        </vp-calendar-week-view-hour-segment>
      </div>
    </div>
    <div
      id="weekViewCalHourCols"
      class="cal-day-columns"
      [class.cal-resize-active]="timeEventResizes.size > 0"
      #dayColumns
    >
      <div
        id="weekViewCalHourCol_{{colIndex}}"
        class="cal-day-column"
        [class.cal-weekend]="isWeekend(column.date)"
        *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn; let colIndex = index"
      >
        <div
          *ngFor="
            let timeEvent of column.events;
            trackBy: trackByDayOrWeekEvent
          "
          #event
          class="cal-event-container"
          [class.cal-draggable]="
            timeEvent.event.draggable && timeEventResizes.size === 0
          "
          [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
          [class.cal-ends-within-day]="!timeEvent.endsAfterDay"
          [ngClass]="timeEvent.event.cssClass"
          [hidden]="timeEvent.height === 0 && timeEvent.width === 0"
          [style.top.px]="timeEvent.top"
          [style.height.px]="timeEvent.height"
          [style.left.%]="timeEvent.left"
          [style.width.%]="timeEvent.width"
          mwlResizable
          [resizeSnapGrid]="{
            left: dayColumnWidth,
            right: dayColumnWidth,
            top: eventSnapSize || hourSegmentHeight,
            bottom: eventSnapSize || hourSegmentHeight
          }"
          [validateResize]="validateResize"
          [allowNegativeResizes]="true"
          (resizeStart)="timeEventResizeStarted(dayColumns, timeEvent, $event)"
          (resizing)="timeEventResizing(timeEvent, $event)"
          (resizeEnd)="timeEventResizeEnded(timeEvent)"
          mwlDraggable
          dragActiveClass="cal-drag-active"
          [dropData]="{ event: timeEvent.event, calendarId: calendarId }"
          [dragAxis]="{
            x: timeEvent.event.draggable && timeEventResizes.size === 0,
            y: timeEvent.event.draggable && timeEventResizes.size === 0
          }"
          [dragSnapGrid]="
            snapDraggedEvents
              ? { x: dayColumnWidth, y: eventSnapSize || hourSegmentHeight }
              : {}
          "
          [ghostDragEnabled]="!snapDraggedEvents"
          [validateDrag]="validateDrag"
          (dragStart)="dragStarted(dayColumns, event, timeEvent)"
          (dragging)="dragMove(timeEvent, $event)"
          (dragEnd)="dragEnded(timeEvent, $event, dayColumnWidth, true)"
        >
          <div
            id="canEventResizeHandlerTop"
            class="cal-resize-handle cal-resize-handle-before-start"
            *ngIf="
              !timeEvent.startsBeforeDay
            "
            mwlResizeHandle
            [resizeEdges]="{
              left: true,
              top: true
            }"
          ></div>
          <vp-calendar-week-view-event
            [weekEvent]="timeEvent"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipDisabled]="dragActive || timeEventResizes.size > 0"
            [tooltipDelay]="tooltipDelay"
            [customTemplate]="eventTemplate"
            [eventTitleTemplate]="eventTitleTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            [refresh]="eventRefresh"
            (eventClicked)="handleAppointmentClicked(timeEvent.event, $event.isSelecting)"
            (onContextMenuClicked)="onContextMenuClicked.emit($event)"
            (onDoubleClicked)="handleDoubleClickedHandled(timeEvent.event)"
            (longPressClick)="longPressClicked.emit($event)"
          >
          </vp-calendar-week-view-event>
          <div
            id="canEventResizeHandlerBottom"
            class="cal-resize-handle cal-resize-handle-after-end"
            *ngIf="!timeEvent.endsAfterDay
            "
            mwlResizeHandle
            [resizeEdges]="{
              right: true,
              bottom: true
            }"
          ></div>
        </div>

        <div
          id="weekViewCalHourCol_{{colIndex}}_{{hourIndex}}"
          *ngFor="let hour of column.hours; trackBy: trackByHour; let odd = odd; let hourIndex = index"
          class="cal-hour"
          [class.cal-hour-odd]="odd"
        >
          <vp-calendar-week-view-hour-segment
            *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
            [style.height.px]="hourSegmentHeight"
            [segment]="segment"
            [segmentHeight]="hourSegmentHeight"
            [locale]="locale"
            [customTemplate]="hourSegmentTemplate"
            (vpCalClick)="hourSegmentClicked.emit({ date: segment.date })"
            [clickListenerDisabled]="hourSegmentClicked.observers.length === 0"
            mwlDroppable
            [dragOverClass]="
              !dragActive || !snapDraggedEvents ? 'cal-drag-over' : null
            "
            dragActiveClass="cal-drag-active"
            (drop)="eventDropped($event, segment.date, false)"
            (hourSegmentContextMenuClicked)="hourSegmentContextMenuClicked.emit($event)"
          >
          </vp-calendar-week-view-hour-segment>
        </div>
      </div>
    </div>
    <div #weekCalTimeline class="cal-timeline-container">
      <div class="timeline-bar">
        <mat-icon  class="mdi-24px" fontSet="mdi" fontIcon="mdi-ray-start-arrow"></mat-icon>
      </div>
    </div>
  </div>
</div>
