
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy,
  ChangeDetectorRef, NgZone, ViewChild, ElementRef } from "@angular/core";
import { MailFolder } from "../../../models/mail-folder.model";
import { Store } from "@ngrx/store";
import { MailRootState, getSelectedMailFolder } from "../../../store";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil, take } from "rxjs/operators";
import { MailFolderRepository } from "../../../repositories/mail-folder-repository";
import { NavigationExtras, Router } from "@angular/router";
import { CreateFolderComponent } from "../../../create-folder/create-folder.component";
import { ConfirmArgs } from "../../../models/confirm-args";
import { MailOperations } from "../../../../common/enums/mail-enum";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";
import { getMailFolders } from "../../../store/selectors";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { MailFolderOprationDialogComponent } from "../../../mail-folder-operation-dialog/mail-folder-operation-dialog.component";
import { MoveFolderDialogComponent } from "../move-items/move-folder-dialog.component";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { CommonRepository } from "../../../repositories/common-repository";
import { TranslateService } from "@ngx-translate/core";
import { timer, Observable } from "rxjs";
import { DataSource } from "../../../../preference/shared/models";
import { PreferenceService } from "../../../../preference/shared/services/preference.service";
import { ElectronService } from "src/app/services/electron.service";
import { EditFolderPropertiesComponent } from "../folder-edit-properties/folder-edit-properties-dialog.component";
import { ConfirmationData, ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MailService } from "../../services/mail-service";
import { ToastService } from "src/app/common/providers/toast.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VncLibraryService } from "vnc-library";

const FOLDERS_PER_PAGE = 20;
@Component({
  selector: "vp-sub-folder-dialog",
  templateUrl: "./sub-folders.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubFolderComponent implements OnInit, OnDestroy {

  mailFolders: MailFolder[] = [];
  private isAlive$ = new Subject<boolean>();
  selectedMailFolder: MailFolder = null;
  rootUserFolders: MailFolder[] = [];
  globalOperationIcon = "add";
  syncPolling: any;
  folderInAction: MailFolder;
  pageNumber = 1;
  loadedFolders: MailFolder[] = [];
  showSearch = false;
  MailConstants = MailConstants;
  searchText: string = "";
  @ViewChild("folderSearchInput", { static: false }) folderSearchInput: ElementRef<HTMLInputElement>;
  constructor(
    private vncLibraryService: VncLibraryService,
    private dialogRef: MatDialogRef<SubFolderComponent>,
    private store: Store<MailRootState>,
    private mailFolderRepository: MailFolderRepository,
    private router: Router,
    private mailBroadcaster: MailBroadcaster,
    private matDialog: MatDialog,
    private commonRepository: CommonRepository,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public folderData: any,
    private preferenceService: PreferenceService,
    private electronService: ElectronService,
    private mailService: MailService,
    private toastService: ToastService
  ) {
    if (folderData.rootFolder) {
      this.selectedMailFolder = folderData.rootFolder;
    }
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit() {
    this.store.select(getMailFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res) {
        this.rootUserFolders = [];
        res.forEach(folder => {
          if (!this.isSystemFolder(folder)) {
            this.rootUserFolders.push(folder);
          }
          if (this.selectedMailFolder && !this.selectedMailFolder.children) {
            const folder = res.find(f => f.id === this.selectedMailFolder.id);
            if (folder) {
              this.selectedMailFolder = folder;
            }
          }
        });
        this.loadFolders();
      }
    });

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_FOLDER_MODAL).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    });

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_SEARCH_FOLDER_FORM).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.showSearch = false;
      this.changeDetectionRef.markForCheck();
    });

    this.mailBroadcaster.on<any>(MailConstants.BROADCAST_MOVE).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res) {
        this.removeTargetFolder(res);
      }
    });

    this.mailBroadcaster.on<any>(MailConstants.BROADCAST_UNDO_MOVE).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res) {
        this.restoreTargetFolder(res);
      }
    });

    this.store.select(getSelectedMailFolder).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.changeDetectionRef.detectChanges();
    });

    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  getChildrens(folder: MailFolder) {
    this.selectedMailFolder = folder;
    this.changeDetectionRef.markForCheck();
  }

  getParentFolder() {
    if (!this.selectedMailFolder || this.isSystemFolder(this.selectedMailFolder)) {
      this.close();
      return;
    }
    const rootFolder: MailFolder = this.mailFolderRepository.getRootFolder(
      this.selectedMailFolder
    );
    if (rootFolder) {
      this.selectedMailFolder = this.commonRepository.getSelectedSubFolder(
        this.selectedMailFolder.l,
        rootFolder
      );
    }
    this.changeDetectionRef.markForCheck();
  }

  routeToFolder(folder) {
    this.mailBroadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
    if (folder.owner && !folder.oname) {
      this.removeBrokenLinkFolder(folder);
      return;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "folderId": folder.id,
      }
    };
    const absFolderPath = this.mailFolderRepository.getAbsolutePathShareFolder(folder);
    if (this.electronService.isElectron) {
      this.electronService.setToStorage(MailConstants.SELECTED_FOLDER_ABSPATH, absFolderPath);
    } else {
      localStorage.setItem(MailConstants.SELECTED_FOLDER_ABSPATH, absFolderPath);
    }
    if (this.router.url === "/mail/folder/" + folder.id) {
      this.mailBroadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
    }
    this.router.navigate(["/mail/folder", folder.id]);
    this.close();
  }

  createNewFolder(folder: MailFolder) {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.matDialog.open(CreateFolderComponent, {
      width: "480px",
      height: "200px",
      autoFocus: true,
      panelClass: "mail_folder-create_dialog",
      data: { targetFolder: folder, isRename: false, isRootLevel: true }
    });
  }

  openMoveFolderDialog(folder: MailFolder) {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      data: { folderToMove: folder, isFolderMoveOperation: true },
      panelClass: "move__dialog",
    };
    const dialogRef = this.matDialog.open(MoveFolderDialogComponent, dialogArgs);
  }

  openActionList(folder: MailFolder) {
    if (folder.owner && !folder.oname) {
      this.removeBrokenLinkFolder(folder);
      return;
    }
    const opeartionDialogRef = this.matDialog.open(MailFolderOprationDialogComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "240px",
      autoFocus: true,
      disableClose: true,
      panelClass: "operation-list-dialog",
      data: { targetFolder: folder }
    });

    opeartionDialogRef.afterClosed().subscribe(operation => {
      if (!operation) {
        return;
      }
      switch (operation.operationType) {
        case MailOperations.CreateFolder:
          this.createNewFolder(operation.targetFolder);
          break;
        case MailOperations.MarkAllRead:
          this.mailFolderRepository.markAllMailRead(operation.targetFolder);
          break;
        case MailOperations.EmptyFolder:
          this.emptyFolder(operation.targetFolder);
          break;
        case MailOperations.TrashFolder:
          this.deleteFolder(operation.targetFolder);
          break;
        case MailOperations.RenameFolder:
          this.renameFolder(operation.targetFolder);
          break;
        case MailOperations.MoveFolder:
          this.openMoveFolderDialog(operation.targetFolder);
          break;
        case MailOperations.ShareFolder:
          this.shareFolder(operation.targetFolder);
          break;
        case MailOperations.ChangeColor:
          this.mailFolderRepository.changeFolderColor(operation.targetFolder, operation.selectedColor);
          break;
        case MailOperations.CloseOperationDialog:
          break;
        case MailOperations.ExternalFolderSync:
          this.folderInAction = operation.targetFolder;
          this.getExternalMail();
          break;
        case MailOperations.EditFolder:
          this.folderInAction = operation.targetFolder;
          this.openEditFolderPropertyDialog();
      }
    });
  }

  renameFolder(folder: MailFolder) {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.matDialog.open(CreateFolderComponent, {
      width: "480px",
      height: "200px",
      autoFocus: true,
      panelClass: "mail_folder-create_dialog",
      data: { targetFolder: folder, isRename: true }
    });
  }

  deleteFolder(folder: MailFolder) {
    const confirmArgs: ConfirmArgs = { operationType: MailOperations.TrashFolder, folderName: folder.name };
    const dialogArgs = {
      width: "319px",
      maxWidth: "85vw",
      autoFocus: false,
      data: confirmArgs,
      panelClass: "sub_folder_delete"
    };
    this.openConfirmDialog(dialogArgs, confirmArgs, folder);
  }

  emptyFolder(folder: MailFolder) {
    let confirmArgs: ConfirmArgs;
    confirmArgs = { operationType: MailOperations.EmptyFolder, folderName: folder.name };
    const dialogArgs = {
      width: "295px",
      maxWidth: "85vw",
      autoFocus: false,
      panelClass: "mail_confirm_dialog",
      data: confirmArgs
    };
    this.openConfirmDialog(dialogArgs, confirmArgs, folder);
  }

  openConfirmDialog(dialogArgs, confirmArgs, folder: MailFolder) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, dialogArgs);
    dialogRef.afterClosed().subscribe(operation => {
      if (operation.confirm) {
        switch (confirmArgs.operationType) {
          case MailOperations.TrashFolder:
            const tempFolder = folder;
            this.mailFolderRepository.deleteMailFolder(tempFolder).subscribe(res => {
              this.removeTargetFolder(folder);
              this.folderSuccessAction(tempFolder, tempFolder.l, "FOLDER_DELETED_MSG", true);
              this.router.navigate(["/mail/inbox"]);
            });
            break;
          case MailOperations.EmptyFolder:
            this.mailFolderRepository.emptyMailFolder(folder);
            break;
        }
      }
    });
  }

  shareFolder(folder: MailFolder) {
    this.mailBroadcaster.broadcast(MailConstants.OPEN_SHARED_FOLDER, folder);
  }

  isSystemFolder(folder: MailFolder): boolean {
    return MailUtils.isSystemFolder(folder);
  }

  private folderSuccessAction(
    sourceFolder: MailFolder,
    currentParentId: string,
    translationKey: string,
    canUndo: boolean
  ): void {
    let translationText = "";
    let undoText = "";
    this.translate
      .get(translationKey)
      .pipe(take(1))
      .subscribe((text: string) => {
        translationText = text;
      });

    if (canUndo) {
      this.translate
        .get("UNDO")
        .pipe(take(1))
        .subscribe((text: string) => {
          undoText = text.toUpperCase();
        });
        this.vncLibraryService.openSnackBar(translationText,"action", undoText, "", 2000, "bottom", "left").subscribe((r) => {
          if(r === "Action") {
            this.performUndo(sourceFolder, currentParentId);
          }
        });
    }
  }

  performUndo(sourceFolder, targetFolderId) {
    this.mailFolderRepository
      .moveMailFolder(sourceFolder.id, targetFolderId)
      .subscribe(res => {
        const rootFolder: MailFolder = this.mailFolderRepository.getRootFolder(sourceFolder);
        this.restoreTargetFolder(sourceFolder);
        let translationText = "";
        this.translate
          .get("FOLDER_RESTORED_MSG")
          .pipe(take(1))
          .subscribe((text: string) => {
            translationText = text;
          });
          this.vncLibraryService.openSnackBar(translationText, "checkmark",
          "", "", 2000, "bottom","left").subscribe(res => {
          });
      });
  }

  removeTargetFolder(folder) {
    if (this.selectedMailFolder) {
      this.selectedMailFolder.children.splice(this.selectedMailFolder.children.indexOf(folder), 1);
    } else if (this.rootUserFolders) {
      this.rootUserFolders.splice(this.rootUserFolders.indexOf(folder), 1);
    }
    this.changeDetectionRef.markForCheck();
  }

  restoreTargetFolder(folder) {
    if (this.selectedMailFolder) {
      this.selectedMailFolder.children.push(folder);
    } else if (this.rootUserFolders) {
      this.rootUserFolders.push(folder);
    }
    this.changeDetectionRef.markForCheck();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  getExternalAccount(node: any): DataSource {
    if (this.preferenceService.dataSource$ !== undefined && this.preferenceService.dataSource$.length > 0) {
      return this.preferenceService.dataSource$.filter(source => source.l.toString() === node.id.toString())[0];
    }
    return null;
  }

  getExternalMail(): void {
    const account: DataSource = this.getExternalAccount(this.folderInAction);
    this.preferenceService.importData(account).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.checkImportStatus(account);
    });
  }

  checkImportStatus(account: DataSource) {
    const errors = [];
    const dataSources: DataSource[] = [];
    this.preferenceService.getImportStatus().subscribe(data => {
      if (data.GetImportStatusResponse) {
        if (data.GetImportStatusResponse[0].pop3) {
          let pop3 = data.GetImportStatusResponse[0].pop3;
          if (!Array.isArray(pop3)) {
            pop3 = [pop3];
          }
          for (const a of pop3) {
            if (a.err) {
              errors.push(a.err);
            }
            dataSources.push(a);
          }
        }
        if (data.GetImportStatusResponse[0].imap) {
          let imap = data.GetImportStatusResponse[0].imap;
          if (!Array.isArray(imap)) {
            imap = [imap];
          }
          for (const a of imap) {
            if (a.err) {
              errors.push(a.err);
            }
            dataSources.push(a);
          }
        }
      }
      if (errors.length === 0) {
        const source: any = dataSources.filter(datasouce => account.id === datasouce.id)[0];
        if (source.isRunning === true) {
          setTimeout(() => {
            this.checkImportStatus(account);
          }, 10000);
        } else {
          let translationText: any = {};
          this.translate.get(["ACCOUNT", "LOADED"]).pipe(take(1)).subscribe((text: string) => {
            translationText = text;
          });
          this.vncLibraryService.openSnackBar(translationText.ACCOUNT + " \"" + account.name + "\" " + translationText.LOADED, "checkmark",
          "", "", 2000, "bottom","left").subscribe(res => {
          });
          if (this.syncPolling) {
            this.syncPolling.unsubscribe();
          }
        }
      }
    });
  }

  openEditFolderPropertyDialog(): void {
    this.matDialog.open(EditFolderPropertiesComponent, {
      maxWidth: "100%",
      autoFocus: true,
      panelClass: "folder_edit_properties_dialog",
      data: { targetFolder: this.folderInAction, moduleType: "mail" }
    });
  }

  removeBrokenLinkFolder(folder: any): void {
    const data: ConfirmationData = {
      action: "delete",
      titleKey: "DELETE_FOLDER_ACTION",
      contentKey: "DELETE_SHARED_FOLDER_NO_LONGER",
      contentParam: { folderName: folder.name },
      actionKey: "DELETE"
    };
    this.openConfirmationDialog(data, { width: "300px" }).pipe(take(1)).subscribe(result => {
      if (result && result.confirmed) {
        this.mailService.folderAction({ id: folder.id, op: "delete" }).subscribe(res => {
          this.removeTargetFolder(folder);
          this.commonRepository.getMailFolders();
          this.toastService.show("FOLDER_DELETED_MSG");
        }, error => {
          this.toastService.showPlainMessage(error);
        });
      }
    });
  }

  private openConfirmationDialog(data: ConfirmationData, style: any): Observable<any> {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      data: data,
      ...style
    });
    return dialogRef.afterClosed();
  }

  loadFolders() {
    let total = this.pageNumber * FOLDERS_PER_PAGE;
    if (total > this.rootUserFolders.length) {
      this.loadedFolders = this.rootUserFolders;
    } else {
      this.loadedFolders = this.rootUserFolders.slice(0, total);
    }
    this.changeDetectionRef.markForCheck();
  }

  onUserScroll($event) {
    if ($event.target.scrollTop + $event.target.offsetHeight > $event.target.scrollHeight - 10) {
      this.pageNumber++;
      this.loadFolders();
    }
  }

  showSearchForm() {
    this.showSearch = true;
    this.changeDetectionRef.markForCheck();
    setTimeout(() => {
      if (this.folderSearchInput) {
        this.folderSearchInput.nativeElement.focus();
      }
    }, 1000);
  }

}
