
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="header">
    {{ 'SPECIFY_MESSAGE_SEND_TIME' | translate }}
</div>
<div class="content">
    {{ 'SELECT_DATE_TIME_SEND_MSG_CONTENT' | translate }}
</div>
<div class="date-time-input">
    <div>
        <mat-form-field class="start-date-width">
            <input matInput [matDatepicker]="startDate" placeholder="{{ 'TIME' | translate }}"
                [formControl]="startDateFormControl" disabled>
            <mat-datepicker-toggle matSuffix [for]="startDate">
                <mat-icon matDatepickerToggleIcon>today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startDate disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="time-input">
            <input matInput type="text" [(ngModel)]="timeInput">
        </mat-form-field>
    </div>
    <div>
        <button mat-icon-button [matMenuTriggerFor]="startTimePointMenu" class="time-input-btn">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
    </div>
</div>
<div class="time-zone">
    <div class="lbl">{{ 'CALENDARS.TIME_ZONE_LBL' | translate }}:</div>
    <div>
        <button [matMenuTriggerFor]="startTimeZoneMenu" class="button-drop-down">
            <span>{{startTimeZone.value}}</span>
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
    </div>
</div>

<div class="footer">
    <button mat-button (click)="okListener()">
        {{ 'COMMON.OK' | translate }}
    </button>
    <button mat-button (click)="close()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>
</div>

<mat-menu #startTimePointMenu="matMenu">
    <button mat-menu-item *ngFor="let timepoint of timePoints" (click)="handleStartTimeChanges(timepoint)">
        <span *ngIf="browserLang === 'en'">{{ timepoint | date: "hh:mm a" }}</span>
        <span *ngIf="browserLang === 'de'">{{ timepoint | date: "HH:mm" }}</span>
    </button>
</mat-menu>
<mat-menu #startTimeZoneMenu="matMenu" class="cal-dropdown-menu">
    <button mat-menu-item *ngFor="let timeZoneItem of timeZone" (click)="handleStartTimeZoneChanges(timeZoneItem)">
        <span>{{ timeZoneItem.value }}</span>
    </button>
</mat-menu>