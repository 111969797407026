
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { UserProfile } from "../shared/models";
import { MailUtils } from "../mail/utils/mail-utils";
import { IframeAPI, MimeVerificationError, SealApiError, SealService, StatusApiError } from "@vereign/lib-seal";
import LibMime from "@vereign/lib-mime";
import { environment } from "src/environments/environment";

const IFRAME_URL = localStorage.getItem("vframeURL") || "https://gmail.app.vereign.com/vframe";

export class Utils {
  static escapeRegExpStr(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  static async dataUrlToFile(dataUrl: string, fileName: string): Promise<any> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    let file = new File([blob], fileName, { type: "image/png" });
    if (environment.isCordova) {
      file = new FileObject([blob], fileName, { type: "image/png" });
    }
    return file;
  }

  static async createSeal(mime, nonce, email) {
    let sealData: any = {};
    return new Promise(async (resolve, reject) => {
      try {
        const sealAPI: any = await IframeAPI.loadAPI(IFRAME_URL);
        const { token } = await sealAPI.authenticate(localStorage.getItem("api_token"), nonce);
        sealData = await sealAPI.createSeal(mime);
        console.log("[createSeal]", token, sealData);
        resolve({...sealData, sealAPI});
      } catch (error) {
        console.log("[createSeal] error", error);
        reject(error);
      }
    });
  }

  static async verifyReceiptRecord(mime, nonce, email, recipientsEmails: string[]) {
    const sealAPI: any = await IframeAPI.loadAPI(IFRAME_URL);
    const { token } = await sealAPI.authenticate(localStorage.getItem("api_token"), nonce); // arguments obtained from azure/gcloud services

    /**
     * Extract seal data from MIME
     */
    let mimeSealData;
    try {
      mimeSealData = await SealService.extractSealFromMime(mime); // mime is either a "string" or instance of @vereign/lib-mime
    } catch (e) {
      // Meaning: Seal not found
    }

    /**
     * Read recipients statuses data
     */

    // Disclaimer: function returns only statuses that has been settled in cloudflare and has blockchain transactions.

    let recipientsStatuses;
    try {
      recipientsStatuses = await sealAPI.readRecipientsStatusData(
        mimeSealData.sealId
      );
    } catch (e) {
      // Something went wrong. Possible network error.
    }

    /**
      * Decryption and verification of recipients statuses.
      */

  // Disclaimer: having in mind that decryption and verificaiton of statuses are relatively expensive operations,
  // it's advised to perform it asynchronosly and in parallel.
  // In general, it's recommended to implement some sort of pagination in case amount of statuses counts to dozens

    const recipientsVerificationResults = {};
    recipientsStatuses.forEach(async (statusData) => {
      let email;
      try {
        const matchResult = await sealAPI.matchRecipientStatusToEmail(
          statusData,
          recipientsEmails
        );
        email = matchResult.email;
      } catch (e) {
        // Error decrypting/matching recipient status to email
        return;
      }

      try {
        await sealAPI.verifyStatusData(statusData);
        Object.assign(recipientsVerificationResults, { [email]: true });
      } catch (e) {
        if (
          e.name === StatusApiError.NAME &&
          e.type === StatusApiError.TYPES.STATUS_VERIFICATION_ERROR
        ) {
          const statusApiError = e as StatusApiError;

          if (statusApiError.severity === StatusApiError.SEVERITY_FAILURE) {
            // Status data not trustworthy
            Object.assign(recipientsVerificationResults, { [email]: false });
          } else {
            // Possible network error, or some data may not be available yet.
          }
        } else {
          // Something went wrong. Possible network error.
        }
      }

      // Dispatch an event about update in recipientsVerificationResults
    });

  }

  static async publishRecipientStatus(senderSealAPI, mimeSealData, recipientData: {
    email: string,
    publicKey?: string,
    emailProviderDomain?: string, // Optional
  }, authenticateParams: {nonce: string, email: string}) {
    const iframeUrl = IFRAME_URL;
    const sealAPI: any = await IframeAPI.loadAPI(iframeUrl);
    const { token } = await sealAPI.authenticate(localStorage.getItem("api_token"), authenticateParams.nonce); // arguments obtained from azure/gcloud services
    console.log("[publishRecipientStatus] ", token);
    // Recipient checks "read" status with his own iframe
    const emailIsRead = await sealAPI.isEmailRead(mimeSealData.sealId);
    console.log("[publishRecipientStatus] emailIsRead", emailIsRead);
    if (!emailIsRead) {
      const publicKey = await sealAPI.getPublicKey();
      recipientData.publicKey = publicKey;
      // And publishes status with sender iframe
      await senderSealAPI.publishRecipientStatus(mimeSealData, recipientData);

      await sealAPI.markEmailAsRead(mimeSealData.sealId);
      // Must be called once you've done. Otherwise iframes and connections will stay dangling.
      await sealAPI.destroy();
    }
  }

  static async extractQuotedPartFromHTML(text) {
    text = text.split("MIME-Version: 1.0")[1].replace("</pre></body></html>", "").replace(/&lt;/ig, "<").replace(/&gt;/ig, ">");
    const mime = new LibMime(text);
    const {
    htmlDocument,
    quotedNodes,
    quotedPartParent,
    } = await mime.extractQuotedPartFromHTML();
    console.log("[extractQuotedPartFromHTML]", htmlDocument, quotedNodes, quotedPartParent);
    return {
      htmlDocument,
      quotedNodes,
      quotedPartParent,
    };
  }

  static verifySealEmail(text: string) {
    if (!text || text.indexOf("MIME-Version: 1.0") === -1 || text.indexOf("filename=seal-image-") === -1) {
      return Promise.resolve();
    }
    return new Promise(async (resolve, reject) => {
      const mime = text.split("MIME-Version: 1.0")[1].replace("</pre></body></html>", "").replace(/&lt;/ig, "<").replace(/&gt;/ig, ">");
      console.log("[verifySealEmail]", mime);

      let mimeSealData;
      try {
        mimeSealData = await SealService.extractSealFromMime(mime); // mime is either a "string" or instance of @vereign/lib-mime
      } catch (e) {
        console.log("[verifySealEmail] Seal not found: ", e);
        // Meaning: Seal not found
      }

      console.log("[verifySealEmail]", mimeSealData);
      let senderStatusData;
      let statusVerificationResult;
      let senderSealAPI;
      if (mimeSealData) {
        // Proceed with the loading of the Iframe API
        try {
          senderSealAPI = await IframeAPI.loadAPIForSeal(mimeSealData.sealUrl);
        } catch (e) {
          // Error loading sender iframe API
        }

        /**
         * Read status data of sender
         */
        try {
          senderStatusData = await senderSealAPI.readSenderStatusData(
            mimeSealData.sealId
          );
        } catch (e) {
          if (e.name === StatusApiError.NAME) {
            const statusApiError = e as StatusApiError;
            if (
              statusApiError.type === StatusApiError.TYPES.STATUS_DATA_NOT_AVAILABLE
            ) {
              // Status data is not available yet. Interrupt verification and show pending view.
              // If long enough time has passed since the message has been sent and status is not available yet,
              // consider it as it never made to the storage
            } else if (
              statusApiError.type ===
              StatusApiError.TYPES.BLOCKCHAIN_TRANSACTION_PENDING
            ) {
              senderStatusData = statusApiError.data;
              // Status data is available, but didn't make it to blockchain yet.
              // Proceed with email verification flow, skip status verification, and display status verification as pending
            } else {
              // Something went wrong. Possible network error.
            }
          } else {
            // Something went wrong. Possible network error.
          }
        }
        console.log("[verifySealEmail] senderStatusData", senderStatusData);
        /**
         * Verify sender status data
         * (skip in case statusApiError.type === StatusApiError.TYPES.BLOCKCHAIN_TRANSACTION_PENDING has been returned on previous step)
         */
        try {
          statusVerificationResult = await senderSealAPI.verifyStatusData(
            senderStatusData
          );
        } catch (e) {
          if (
            e.name === StatusApiError.NAME &&
            e.type === StatusApiError.TYPES.STATUS_VERIFICATION_ERROR
          ) {
            const statusApiError = e as StatusApiError;

            if (statusApiError.severity === StatusApiError.SEVERITY_FAILURE) {
              // Status data not trustworthy
            } else if (statusApiError.severity === StatusApiError.SEVERITY_WARNING) {
              // Something went wrong. Possible network error.
            } else if (statusApiError.severity === StatusApiError.SEVERITY_INFO) {
              // Some data may not be available yet. Display status verification as pending.
            }
          } else {
            // Something went wrong. Possible network error.
          }
        }
        console.log("[verifySealEmail] statusVerificationResult", statusVerificationResult);
        // Integrator application displays blockchain data from `statusVerificationResult`

        /**
         * Verify email
         */
        try {
          const { errors } = await senderSealAPI.verifyEmail(
            mimeSealData,
            senderStatusData,
            mime
          );
          console.log("[verifySealEmail] errors", errors);
          errors.forEach((e) => {
            if (e.type === MimeVerificationError.TYPES.SUBJECT_NOT_VERIFIED) {
              // Mark subject as not trustworthy
            } else if (e.type === MimeVerificationError.TYPES.SENDER_NOT_VERIFIED) {
              // Mark sender as not trustworthy
            } else if (e.type === MimeVerificationError.TYPES.RECIPIENTS_NOT_VERIFIED) {
              // Mark to/cc as not trustworthy
            } else if (e.type === MimeVerificationError.TYPES.PARTS_NOT_VERIFIED) {
              if (e.severity === MimeVerificationError.SEVERITY_WARNING) {
                // One of the parts is trustworthy but insignificantly amended, e.g. HTML layout changed
              } else {
                // Mark HTML/Plain as not trustworthy
              }
            } else if (
              e.type === MimeVerificationError.TYPES.ATTACHMENTS_NOT_VERIFIED
            ) {
              const errorData =
                e.data as MimeVerificationError.AttachmentsVerificationErrorData;
              if (errorData.filesMissing === true) {
                // Alert user that some files in his email are missing
              }

              errorData.attachments.forEach((attachment) => {
                const { sha256, filename } = attachment;

                // Find out respective email attachment by matching it's name to `filename`
                // and base64(SHA256(<bytes>)) to `sha256` and mark it as not verified
                //
                // IMPORTANT_NOTE: in case email contains multiple equal attachments, mark all of them as not verified
              });
            } else if (e.type === MimeVerificationError.TYPES.MIME_NOT_VERIFIED) {
              // The whole MIME is not verified, mark everything related to email verification as not trustworthy
            }
          });
        } catch (e) {
          if (e.name === SealApiError.NAME) {
            // Seal reding/verification error.
            // Show Integrity broken
          }
          // Mark everything related to email verification as possibly not trustworthy
        }
      }
      resolve({senderSealAPI, mimeSealData, senderStatusData, statusVerificationResult});
    });

  }

  static insertAt(base: string, insert: string, index: number) {
    return base.slice(0, index) + insert + base.slice(index);
  }

  static isAndroid() {
    return /android/i.test(navigator.userAgent || navigator.vendor) ? true : false;
  }

  static isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static parseUserProfile(contact) {
    const contactUser = typeof contact === "string"
      ? JSON.parse(contact)
      : contact;
    const user: UserProfile = {};
    user.firstName = contactUser.firstName;
    user.lastName = contactUser.lastName;
    user.email = contactUser.email;
    user.avtarUrl = contactUser.avtarUrl;
    user.fullName = contactUser.fullName;
    user.firstLastCharacters = contactUser.firstLastCharacters;
    user.contactId = contactUser.contactId;
    user.app = "mail";
    return user;
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static validateEmail(mail) {
    if ((mail.indexOf(",") > -1) || (mail.trim().indexOf(" ") > -1)|| (mail.indexOf(";") > -1)) {
      return (false);
    }
    // tslint:disable-next-line:max-line-length
    // let exp = /^[\u00C0-\u017Fa-zA-Z0-9_]+([\.-][\u00C0-\u017Fa-zA-Z0-9_]+)*@[\u00C0-\u017Fa-zA-Z0-9_]+([\.-][\u00C0-\u017Fa-zA-Z0-9_]+)*(\.[\u00C0-\u017Fa-zA-Z0-9_]{2,20})+$/;
    let exp = /^[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+(\.[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+)*@[A-Za-z0-9]+([-.][A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

    if (exp.test(mail)) {
      return (true);
    }
    return (false);
  }

  static replacePtoDiv(str: string): string {
    if (str !== undefined && str !== null) {
      console.log("[replacePtoDiv]", str);
      str = str.replace(/<p>/g, "<div>").replace(/<\/p>/g, "</div>");
      return str;
    }
    return "";
  }

  static quillGetHTML(inputDelta) {
    const tempQuill = new window.sharedQuill(document.createElement("div"));
    // console.log("[quillGetHTML]", inputDelta);
    tempQuill.setContents(inputDelta, "silent");
    return tempQuill.root.innerHTML;
  }

  static processMailContent(quillEditor, originalContent?: string) {
    let topData = [];
    let bottomData = [];
    let signatureData = [];
    if (!quillEditor && !originalContent) {
        return {topData, signatureData, bottomData};
    }
    let content = "";
    if (originalContent) {
      content = originalContent;
    } else if (quillEditor) {
      content = quillEditor.root.innerHTML;
    }
    const originalDelta = quillEditor.clipboard.convert(content.replace(/<p>(\s+)/g, (a, b) => {
      return `${a}${b.replace(/\s/g, "&nbsp;")}`;
    }));
    console.log("[processMailContent] originalDelta", content, originalDelta);
    MailUtils.processList(originalDelta);
    let i = 0;
    let j = 0;
    let startSignatureIndex;
    let endSignatureIndex;
    originalDelta.ops.forEach(op => {
      if (op.attributes && op.attributes.signature) {
        if (startSignatureIndex === undefined) {
          startSignatureIndex = i;
        } else {
          endSignatureIndex = i;
        }
      }
      i++;
    });
    let insertPlaceholder = false;
    let insertEndPlaceholder = false;
    originalDelta.ops.forEach(op => {
      if (op.insert && op.insert.image && op.insert.image.startsWith("cid:")) {
        if (op.attributes) {
          op.attributes["dfsrc"] = op.insert.image;
          op.attributes["alt"] = op.insert.image;
        } else {
          op.attributes = {dfsrc: op.insert.image};
        }
      }
      if (op.attributes && op.attributes.signature) {
        if (startSignatureIndex === j) {
          if (typeof op.insert === "string") {
            op.insert = `START_SIGNATURE${op.insert}`;
          } else {
            insertPlaceholder = true;
          }
        }
        if (endSignatureIndex === j) {
          if (typeof op.insert === "string") {
            op.insert = `${op.insert}END_SIGNATURE`;
          } else {
            insertEndPlaceholder = true;
          }
        }
      }
      j++;
    });
    if (insertPlaceholder) {
      originalDelta.ops.splice(startSignatureIndex, 0,  {insert: "START_SIGNATURE"});
    }
    if (insertEndPlaceholder) {
      originalDelta.ops.splice(endSignatureIndex + 1 + (insertPlaceholder ? 1 : 0), 0,  {insert: "END_SIGNATURE"});
    }
    topData = originalDelta.ops;
    return { topData, signatureData, bottomData };
  }

  static getCharacters(firstName, lastName) {
    if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
      const fName: string = firstName.trim();
      const lName: string = lastName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return (chr1 + chr2).trim();
    } else if (firstName && firstName !== undefined) {
      const fName: string = firstName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      return chr1;
    } else if (lastName && lastName !== undefined) {
      const lName: string = lastName.trim();
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return chr2;
    } else {
      return "NA";
    }
  }

}
